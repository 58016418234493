@mixin FLEX_COLUMN {
  display: flex;
  flex-direction: column;
}

@mixin FLEX_ALIGN_CENTER {
  display: flex;
  align-items: center;
}

@mixin FLEX_CENTER {
  display: flex;
  align-items: center;
  justify-content: center;
}
