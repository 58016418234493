.dash-container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f6f6f6;
  padding: 0 32px;

  // height: 100vh;

  .dash-comp-container {
    display: flex;
    flex-direction: column;
    margin: 60px 0;
    // padding: 69px 177px 0 177px;
    max-width: 1120px;
    width: 100%;

    .my-dash-title {
      display: flex;
      justify-content: space-between;
      color: #1c1c1c;
      font-weight: 700;
      font-size: 36px;
      line-height: 100%;
      height: 36px;
      // line-height: 60px;
      // margin-bottom: 37px;
      .user-organization-logo {
        height: inherit;
      }
    }
    .dash-title-divider {
      border-top: 1px solid black;
      margin: 24px 0;
    }
    .dash-section-box {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      // .dash-book-req-holder {
      //   flex-direction: column;
      // }
      .dash-section-holder {
        display: flex;
        gap: 20px;
        .dash-stats-container,
        .dash-bookmarks-container,
        .dash-most-container,
        .dash-reqs-container,
        .dash-breakdown-container {
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          background-color: $WHITE;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
            0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.04);
          padding: 24px;

          .dash-mod-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: #1c1c1c;
          }
        }

        .dash-bookmarks-container {
          // padding: 24px 24px 57px 24px;
          // height: 337px;
          // height: 100%;
        }

        .dash-stats-container {
          justify-content: space-evenly;
          // padding: 32px 48px 32px 24px;
        }

        .dash-reqs-container {
          padding: 30px 24px 24px 30px;
        }
      }
    }
  }
}

#private-card-tooltip {
  color: #1f2126;
  background-color: #ffffff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  width: fit-content;
  max-width: 200px;
  padding: 12px;
  font-size: 16px;

  .rating-updated-tooltip-popup-arrow {
    background-color: #2F5BEA;
  }
  .rating-updated-info-title{
    text-align: center;
  }
}

.no-private-card-container {
  display: flex;
  padding: 40px 40px 60px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.no-private-card-title {
  color: #1C1C1C;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.no-private-card-body {
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.no-private-card-c2a {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 36px;
  height: 45px;
  background: #2F5BEA;
  border: 1px solid #1F2126;
  border-radius: 8px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.08em;
  color: #FFFFFF;

  &:hover {
    background: #264ABE;
    transition: all 0.5s;
  }
}

.private-card-caption-1 {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 14px;
  background-color: #000;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: inherit;
  overflow-wrap: break-word;
  hyphens: auto;
  color: #FFFFFF;
  font-size: 28px;
  text-orientation: mixed;
  writing-mode: vertical-lr;
  text-align: center;
}
.private-card-caption-2 {
  position: absolute;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  background-color: #fff;
  top: 0;
  left: 0;
  border-radius: inherit;
  overflow-wrap: break-word;
  hyphens: auto;
  color: #FFFFFF;
  font-size: 20px;

  .user-organization-logo{
    width: 100%;
  }
}
.private-card-caption-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  letter-spacing: 8px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: inherit;
  overflow-wrap: break-word;
  hyphens: auto;
  text-orientation: mixed;
  writing-mode: vertical-lr;
  text-align: center;
  color: white;
  background: #000;
  mix-blend-mode: multiply;
  font-size: 50px;
  font-weight: 700;
}
.private-card-caption-4{
  position: absolute;
  width: 75%;
  height: 50%;
  padding: 4px;
  background-color: #000;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 0 0 auto;
  border-radius: inherit;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: #FFFFFF;
  font-size: 32px;
  text-orientation: mixed;
  writing-mode: vertical-lr;
  text-align: center;
}
.private-card-caption-5 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: inherit;
  overflow-wrap: break-word;
  hyphens: auto;
  text-orientation: mixed;
  writing-mode: vertical-lr;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  color: black;
  mix-blend-mode: screen;
  background-color: #fff;
  border-right: 4px solid;
}
