.my-brk-donut {
  // height: 290px;
  // width: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 35px;

  .donut-box {
    height: 180px;
    width: 180px;
    // min-height: 290px;
    // min-width: 290px;
  }
  .donut-paths {
    // filter: drop-shadow(0 2px 10px rgba(5, 241, 162, 0.993));
    // filter: drop-shadow(0 2px 6px rgba(255, 17, 17, 0.742));
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.2));
  }
}
