// @use "colors";
// @use "typography" as t;

.progress-container-styles {
  height: 20px;
  width: 100%;
  background-color: rgba(44, 159, 44, 0.1);
  border-radius: 8px;
  bottom: 0;
}

.progress-container {
  margin-bottom: 3.156rem !important;
}

.progress-styles {
  border-radius: 8px;
  background-color: $GREEN;
  height: 100%;
}

.progress-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-days-left {
  font-weight: 700 !important;
  line-height: 1.302rem;
}

.progress-end-date {
  font-weight: 400 !important;
}
.progress-end-date span {
  font-weight: 700 !important;
}
