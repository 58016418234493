.dash-container {
  .dash-comp-container {
    .dash-section-box {
      .dash-section-holder {
        .all-reqs-container {
          display: flex;
          gap: 20px;
          flex-direction: row;
          max-width: 1120px;
          width: 100%;
          padding: 30px 24px;

          .all-requests-container {
            overflow-y: auto;
            width: 100%;
            display: flex;
            max-height: 600px;
            flex-direction: column;
            row-gap: 20px;
            max-width: 400px;
            padding-bottom: 10px;

            .all-req-module {
              //   background-color: blue;
              max-width: 400px;
              max-height: 130px;
              height: 100%;
              transition: ease-in-out 0.3s;

              //   &:active{
              //     background-color: #c56d6d;
              //   }

              &:hover {
                cursor: pointer;
                background-color: rgba(155, 191, 191, 0.631);
              }
            }
            .selected_req {
              background-color: rgba(155, 191, 191, 0.448);
            }
          }

          .right-req-title {
            font-size: 30px;
          }
        }
      }
    }
  }
}
