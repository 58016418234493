.pagination-container {
  display: flex;
  margin-bottom: 30px;

  .pagination-btn {
    border-radius: 100px;
    background-color: transparent;
    border: 1px solid $DARK_GRAY;
    width: 3.429rem;
    height: 3.429rem;
    margin: 0 10px;
    background-repeat: no-repeat;
    background-position: center;
    transition: border 0.1s ease-in-out 0s;

    .right-p-btn {
      background-size: contain;
      width: 24px;
      height: 24px;
      background-image: url('../../assets/chev-right.svg');
    }

    .left-p-btn {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-image: url('../../assets/chev-left.svg');
    }
  }

  .pagination-btn.inactive_page_btn {
    border: 1px solid #b8b8b8;
    cursor: default;
    transition: border 0.1s ease-in-out 0s;

    .right-p-btn {
      background-image: url('../../assets/chev-right-dis.svg');
      transition: background-image 0.1s ease-in-out 0s;
    }
    .left-p-btn {
      background-image: url('../../assets/chev-left-dis.svg');
      transition: background-image 0.1s ease-in-out 0s;
    }
  }

  .pagination-button-holder {
    // width: 300px;
    display: flex;

    .activePage {
      background-color: $BLUE;
      border: 1px solid #185bc3;
      color: $WHITE;
      transition: background-color 0.2s ease-in-out 0s;
    }

    .pagination-count-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      width: 3.429rem;
      height: 3.429rem;
      margin: 0 5px;
      border: none;
    }
  }
}

.pagination-svg-div {
  display: flex;
  align-items: flex-end;

  .three-dots {
    background-image: url('../../assets/three-dots.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 20px;
    height: 20px;
  }
}
