// CoS Modal responsive styles
@media (min-width: 320px) {
  .modal-title-element {
    @include fluid-type(font-size, 320px, 699px, 20px, 20px);
  }
  .md-intro-par {
    font-weight: 400;
    @include fluid-type(font-size, 320px, 699px, 14px, 16px);
    margin-bottom: 32px;
  }
  .modal-body-element {
    .data-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
  .cos-table-container {
    display: flex;
    @include fluid-type(column-gap, 320px, 1440px, 2px, 5px);

    // width: 1255px;
    @include fluid-type(width, 320px, 599px, 310px, 565px);
    @include fluid-type(width, 600px, 1440px, 416px, 1255px);

    .cos-left-col {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      @include fluid-type(width, 320px, 599px, 70.56px, 115px);
      @include fluid-type(width, 600px, 1440px, 85px, 255px);

      // width: 248px;
      // width: 100%;

      .td-upper-title {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #d8e1ff;
        background-color: #f3f6ff;
        font-weight: 400;
        padding: 8px 10px;
        text-align: center;
        @include fluid-type(height, 320px, 599px, 108px, 110px);
        @include fluid-type(height, 600px, 1440px, 120px, 66px);

        //  @include fluid-type(width, 320px, 599px, 108px, 66px);
      }

      .td-lat-title {
        border: 1px solid #e0e0e1;
        width: 100%;
        padding: 8px 10px;
        text-align: center;
        line-height: 26px;
        font-weight: 500;
        font-size: 20px;

        // @include fluid-type(width, 320px, 1440px, 55px, 248px);
        @include fluid-type(font-size, 320px, 1440px, 10px, 20px);
        @include fluid-type(padding-top, 320px, 1440px, 0px, 8px);
        @include fluid-type(padding-bottom, 320px, 1440px, 0px, 8px);
        @include fluid-type(padding-right, 320px, 1440px, 0px, 10px);
        @include fluid-type(padding-left, 320px, 1440px, 0px, 10px);
      }
      .table-logo-container {
        height: 42px;
        border: 1px solid #d8e1ff;
        background-color: #f3f6ff;
        font-weight: 400;
        padding: 8px 10px;
        text-align: center;

        .logoMark {
          width: 22px;
          height: 22px;
        }
      }
    }

    .cos-left-col > * {
      @include fluid-type(font-size, 320px, 1440px, 9px, 16px);
    }

    .cos-right-col {
      @include fluid-type(width, 320px, 599px, 286px, 348px);
      @include fluid-type(width, 600px, 1440px, 349px, 1002px);

      display: flex;
      flex-direction: column;
      text-align: center;
      flex-grow: 1;
      row-gap: 5px;
      max-width: 1002px;

      .cos-row {
        display: flex;
        // column-gap: 5px;
        @include fluid-type(column-gap, 320px, 1440px, 2px, 5px);

        .td-lat-numbers {
          border: 1px solid #e0e0e1;
          // width: 100%;
          // width: 248px;

          // padding: 8px 10px;
          width: 100%;
          // @include fluid-type(width, 320px, 1440px, 55px, 248px);
          @include fluid-type(font-size, 320px, 1440px, 10px, 20px);
          @include fluid-type(padding-top, 320px, 1440px, 0px, 8px);
          @include fluid-type(padding-bottom, 320px, 1440px, 0px, 8px);
          @include fluid-type(padding-right, 320px, 1440px, 0px, 10px);
          @include fluid-type(padding-left, 320px, 1440px, 0px, 10px);

          text-align: center;
          line-height: 26px;
          font-weight: 500;
          // font-size: 20px;
        }
      }

      .cos-right-titles {
        display: flex;
        // column-gap: 5px;
        @include fluid-type(column-gap, 320px, 1440px, 2px, 5px);

        .td-upper-title {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border: 1px solid #d8e1ff;
          background-color: #f3f6ff;
          font-weight: 400;
          @include fluid-type(height, 320px, 599px, 108px, 110px);
          @include fluid-type(height, 600px, 1440px, 120px, 66px);
          // @include fluid-type(width, 320px, 599px, px, 66px);
          // @include fluid-type(width, 600px, 1440px, 120px, 66px);

          @include fluid-type(font-size, 320px, 1440px, 9px, 16px);

          @include fluid-type(padding-top, 320px, 1440px, 0px, 8px);
          @include fluid-type(padding-bottom, 320px, 1440px, 0px, 8px);
          @include fluid-type(padding-right, 320px, 1440px, 0px, 10px);
          @include fluid-type(padding-left, 320px, 1440px, 0px, 10px);

          // height: 100%;
        }
      }
      .cos-right-header {
        padding: 8px 10px;
        border: 1px solid #d8e1ff;
        background-color: #f3f6ff;
        height: 42px;

        .table-title {
          font-weight: 400;
        }
      }
    }
  }

  .no-card-message {
    display: flex;
    // background-color: rgba(0, 0, 0, 0.07);
    @include fluid-type(font-size, 320px, 1440px, 12px, 30px);
    // font-size: 30px;
    max-width: 1120px;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 50vh;
  }

  .search-div .css-1xc3v61-indicatorContainer {
    padding: 0;
  }
}

// Small
@media (min-width: 320px) and (max-width: 599px) {
  .search-div {
    max-width: 40% !important;
  }
  #search-party {
    max-width: 100% !important;
  }
  .search-icon {
    width: 14px !important;
    height: 14px !important;
  }

  .venue-filter {
    .css-1dimb5e-singleValue {
      font-size: 14px !important;
    }

    .css-d7l1ni-option {
      font-size: 14px !important;
    }
    .css-10wo9uf-option {
      font-size: 14px !important;
    }

    .css-1jqq78o-placeholder {
      font-size: 14px !important;
    }
  }

  .search-div {
    .css-t3ipsp-control {
      height: 26px !important;
      border: 0.063rem solid #2c2e30;
      border-radius: 0.5rem;
      max-width: 100% !important;
    }
    .css-13cymwt-control {
      height: 26px !important;
      border: 0.063rem solid #2c2e30;
      border-radius: 0.5rem;
      max-width: 100% !important;
      // margin-right: 10px !important;
    }
    .css-cpryk8-menu {
      width: max-content;
      max-width: 200px;
    }
    .css-4yvjq5-Input {
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }
    .css-1u9des2-indicatorSeparator {
      margin: 6px 0px !important;
    }

    .css-1xc3v61-indicatorContainer {
      padding: 0 !important;
    }

    .css-15lsz6c-indicatorContainer {
      padding: 0 !important;
    }

    .css-1dimb5e-singleValue {
      font-size: 12px !important;
    }

    .css-1jqq78o-placeholder {
      font-size: 12px !important;
    }

    .css-hlgwow {
      padding: 0px 8px !important;
    }
    .css-1fdsijx-ValueContainer {
      font-size: 12px;
    }

    .css-hlgwow {
      font-size: 12px;
    }

    .css-10wo9uf-option {
      font-size: 12px !important;
      padding: 8px 8px !important;
    }

    .css-d7l1ni-option {
      font-size: 12px !important;
      padding: 8px 8px !important;
    }
    .css-tr4s17-option {
      font-size: 12px !important;
      padding: 8px 8px !important;
    }
  }

  //express interest
  .footer-single-cc {
    margin-top: 0px !important;
    padding-top: 60px !important;
  }
  .interest-left {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .interest-right {
    width: 100% !important;
    margin-left: 0px !important;

    .email-box {
      font-size: 11px !important;
      line-height: 14px !important;
      color: #000000;
      width: 180px !important;
      margin-bottom: 16px !important;
    }
    .interest-button {
      margin-top: 10px;
      font-size: 10px;
      font-weight: 700;
      line-height: 13px;
      letter-spacing: 0.08em;
      padding: 2px 16px;
      border-radius: 8px;
      height: 29px;
    }

    textarea {
      font-size: 11px !important;
      height: 80px !important;
    }

    label {
      font-size: 11px !important;
      font-weight: 400;
      line-height: 14px !important;
      letter-spacing: 0.08em;
      margin-bottom: 8px;
    }
  }
  .interest-subsection-title {
    font-size: 9px !important;
    font-weight: 700 !important;
    line-height: 12px !important;
    letter-spacing: 0.04em !important;
    text-align: left !important;
  }
  .interest-text {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
  }
  .sort-icon {
    width: 15px !important;
    height: 16px !important;
  }

  .dropdown-container .nav-item.dropdown a {
    font-size: 12px !important;
    // padding: 0.15rem 0.6rem !important;
    padding: 3px 12px !important;
  }

  .single-case-card .single-card-info .image-tags .rating_tag:before {
    border: 3px solid;
    border-radius: 7px;
  }

  .auth-background {
    background-image: none;

    .auth-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      box-shadow: none;
      padding: 1rem;
      border-radius: revert;

      .success-reset-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        width: 288px;
        height: 30px;
      }

      .success-divider {
        margin-top: 24px;
      }

      .auth-header {
        @include fluid-type(font-size, 320px, 599px, 20px, 20px);
        margin-bottom: 8px;
      }
      .forgot-pass-text {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.02rem;
        margin-bottom: 24px;
        text-align: center;
      }

      .mobile-form-mailto {
        border-top: 1px solid #cacaca;
        padding-top: 1rem;
        font-weight: 400;
        font-size: 12px;
        line-height: 15.62px;
        color: #74757c;

        a {
          text-decoration: none;
        }
      }
      form {
        .forgot-pass-container-m {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: fit-content;
          margin-bottom: 4px;

          .input-labels-m {
            display: flex;
            align-items: center;
          }
        }
        .helper-text a {
          color: #74757c;
          display: flex;
          // height: fit-content;
        }
        .input-labels-m,
        .login-forgot-link {
          font-size: 12px;
          line-height: 15.62px;
          font-weight: 400;
          color: #333333;
          letter-spacing: -0.02rem;
          margin: 0;
        }

        .input-container {
          margin-bottom: 1rem;
          .formik-error {
            font-size: 12px;
          }
          .input-labels-m {
            margin-bottom: 4px;
          }
          .auth-input {
            height: 30px;
            min-width: 288px;
            font-size: 12px;
          }
        }
        .submit-button {
          margin: 0 0 24px 0;
          height: 34px;
          padding: 8px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 288px;
        }
        .ic-login-m {
          margin-bottom: 24px;
        }
      }
      .arbilex-blue-logo-m {
        // background-image: url('../../assets/ArbiLex_logo_blue.svg');
        background-image: url('../../assets/ArbiLex-logo-blue.svg');
        width: 48px;
        height: 45px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 12px;
      }
    }
  }
  .modal-mega-container.modal-90w {
    .modal-content {
      height: 90dvh;
    }
  }
  .modal-dialog,
  .modal-90w,
  .modal-mega-container,
  .modal-xl,
  .modal-mega-container.modal-90w,
  .modal-content,
  .modal-body {
    position: absolute;
    width: 100%;
    margin: 0;
    max-width: 100vw;
    align-items: flex-end;
    height: 100dvh;
  }
  .modal-mega-container.modal-90w .modal-content {
    width: 100vw;
    // max-width: 450px;
  }

  .dropdown-menu {
    font-size: 12px;
  }

  .dropdown-container,
  .filter-sort-container {
    height: fit-content;
    // max-width: 400px;
    width: 100%;
    // padding-bottom: 0px;
  }

  .center-filter-container {
    padding-top: 16px;
  }
  .ref-holder-container {
    .filter-button {
      // padding
      width: 85px;
      font-size: 12px;
      line-height: 15.62px;
      padding: 4px 12px;

      .filter-svg {
        height: 1rem;
      }
    }
  }

  .nav-item .dropdown {
    #sort-by-dropdown {
      // padding
      width: 85px !important;
      font-size: 12px !important;
      line-height: 15.62px !important;

      // .filter-svg {
      //   height: 1rem;
      // }
    }
  }

  .masonry-container,
  .center-filter-container,
  .center-nav-holder {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .masonry-container {
    align-items: center;
  }
  .listing-menu {
    display: none;
  }

  .nav-menu {
    display: none;
  }
  .masonry-layout-holder {
    @include fluid-type(padding-top, 320px, 599px, 12px, 14px);

    .masonry-container {
      flex-direction: column;

      .column-holder {
        width: fit-content;
      }
    }
  }

  .center-nav-holder {
    position: relative;

    .logo-link {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
    }
    .user-menu-dropdown {
      position: absolute;

      right: 1rem;
    }
  }

  #rating-updated-tooltip {
    @include fluid-type(max-width, 320px, 599px, 280px, 480px);
    @include fluid-type(font-size, 320px, 599px, 10px, 14px);

    .rating-updated-info-title{
      @include fluid-type(font-size, 320px, 599px, 10px, 14px);
    }
  }
  
  .single-case-card {
    flex-direction: column;
    // @include fluid-type(height, 320px, 599px, 268px, 320px);
    max-width: 599px;
    .single-card-info {
      .image-tags {
        margin-left: -4px;
        .status-tag,
        .secondary-tag {
          font-size: 10px;
          padding: 2px 6px;
          line-height: 1rem;
          border-radius: 0.5rem;
          text-align: center;
        }
        .rating_tag:before {
          border: 3px solid;
          border-radius: 7px;
        }
        .uncertainty_tag:before {
          border-radius: 7px;
        }
      }
    }
    .single-card-info {
      padding: 12px;
      max-width: 100%;

      .single-card-container {
        .top-card-container {

          .rating-updated-tag-container {
            .rating-updated-tag{
              @include fluid-type(font-size, 320px, 599px, 10px, 14px);
    
              .rating-updated-info-icon {
                @include fluid-type(max-width, 320px, 599px, 10px, 14px);
                @include fluid-type(max-height, 320px, 599px, 10px, 14px);
              }
            }
            .rating-updated-tooltip-popup-container{
              @include fluid-type(max-width, 320px, 599px, 280px, 480px);
              @include fluid-type(font-size, 320px, 599px, 10px, 14px);
            }
            .rating-updated-info-title{
              @include fluid-type(font-size, 320px, 599px, 10px, 14px);
            }
          }

          .single-card-date {
            font-size: 12px;
            font-weight: 400;
            @include fluid-type(font-size, 320px, 599px, 12px, 16px);
          }

          .to-card-link {
            // height: 60px;
            @include fluid-type(min-height, 320px, 599px, 60px, 82px);
            @include fluid-type(line-height, 320px, 599px, 20px, 28px);

            line-height: 20px;

            a {
              // font-size: 16px;
              @include fluid-type(font-size, 320px, 599px, 16px, 24px);

              line-height: 20.48px;
              // @include fluid-type(font-size, 320px, 599px, 16px, 24px);
              // @include fluid-type(line-height, 320px, 599px, 20.48px, 24px);
            }
          }
        }
        .bottom-card-container {
          .single-card-stats {
            @include fluid-type(margin-bottom, 600px, 743px, 12px, 8px);
            @include fluid-type(row-gap, 320px, 599px, 12px, 16px);

            column-gap: 20px;
            height: fit-content;
          }
          .view-case-link-container {
            // margin: 12 0 0 0;
            @include fluid-type(font-size, 320px, 599px, 12px, 14px);
          }
        }
      }
    }
    .stats-paragraph {
      line-height: 10.42px;
      @include fluid-type(font-size, 320px, 599px, 8px, 10px);

      font-weight: 700;

      span {
        @include fluid-type(line-height, 320px, 599px, 15.62px, 20px);
        @include fluid-type(font-size, 320px, 599px, 12px, 16px);
      }
    }

    .last-stat-p {
      @include fluid-type(margin-bottom, 320px, 599px, 12px, 20px);
    }

    .card-image-container {
      height: 40px;
      width: 100%;

      .image-tags {
        height: 100%;
        align-items: center;

        .secondary-tag,
        .status-tag {
          display: flex;
          align-items: center;
        }
      }
      .card-image {
        width: 100%;
        border-radius: 5px 5px 0 0;
        
        .private-card-caption-5 {
          writing-mode: revert;
          font-size: 32px;
          border-right: 0px;
          border-bottom: 2px solid;
        }
      }
    }
  }

  .pagination-container {
    display: flex;

    .pagination-btn {
      // display: flex;
      // background-repeat: no-repeat;
      width: 30px;
      height: 30px;
      padding: 0;

      .left-p.btn {
        width: 100%;
      }
      .right-p.btn {
        width: 100%;
      }
    }

    .pagination-button-holder {
      .pagination-count-btn {
        width: 30px;
        height: 30px;
        font-size: 12px;
        margin: 0 5px;
      }
    }
  }

  .case-type-container {
    flex-direction: column;
    font-size: 14px;
  }

  #filter-container {
    .case-type-section {
      .filter-title {
        font-size: 16px;
      }
    }
  }
  .date-overlay {
    input {
      font-size: 14px;
    }
  }

  #filter-venue-dropdown {
    font-size: 14px;
  }
  .min-max-container {
    font-size: 14px;
  }
  .filter-dropdown {
    // width: 450px;
  }

  .right-checkbox-spacing {
    margin-top: 8px;
    display: flex;
    position: relative;
    left: auto;
  }

  .filter-range-separator {
    display: none;
  }

  .long-section {
    .case-type-container {
      row-gap: 10px;
    }
  }

  .dropdown-menu.show {
    font-size: 12px;
  }

  //Singel case Small

  .single-case-body-container {
    padding: 16px;

    .basic-case-information {
      display: flex;
      flex-direction: column;

      .rating-updated-tag-container {
        .rating-updated-tag{
          @include fluid-type(font-size, 320px, 599px, 10px, 14px);

          .rating-updated-info-icon {
            @include fluid-type(max-width, 320px, 599px, 10px, 14px);
            @include fluid-type(max-height, 320px, 599px, 10px, 14px);
          }
        }
        .rating-updated-tooltip-popup-container{
          @include fluid-type(max-width, 320px, 599px, 280px, 480px);
          @include fluid-type(font-size, 320px, 599px, 10px, 14px);
        }
        .rating-updated-info-title{
          @include fluid-type(font-size, 320px, 599px, 10px, 14px);
        }
      }

      .case-base-info {
        height: fit-content;
      }

      .single-link-container {
        p {
          @include fluid-type(font-size, 320px, 599px, 10px, 14px);
          @include fluid-type(line-height, 320px, 599px, 16px, 22.4px);
        }
        .back-listing-link {
          div {
            display: none;
            // @include fluid-type(font-size, 905px, 1239px, 14px, 18px);
          }
        }
      }
      .case-title {
        @include fluid-type(font-size, 320px, 599px, 16px, 24px);
        @include fluid-type(line-height, 320px, 599px, 20.48px, 30.72px);
        letter-spacing: 0.64px;
        @include fluid-type(margin-bottom, 320px, 599px, 16px, 24px);
      }
      .case-tags {
        height: fit-content;
        @include fluid-type(margin-bottom, 320px, 599px, 16px, 24px);
        margin-top: 20px;
        letter-spacing: 1px;

        div {
          font-size: 10px;
          line-height: 14px;
          padding: 4px 6px;
          height: auto;
        }
      }
      .case-basic-info {
        display: flex;
        flex-direction: column;

        margin: 12px 0 36px 0;

        .basic-info-container {
          flex-basis: auto;
          gap: 12px;
          .counsel-holder {
            gap: 12px;
            flex-direction: column;
            margin-bottom: 0;
          }
          .basic-info-title {
            height: fit-content;
            width: fit-content;
            margin: 0;

            h4 {
              @include fluid-type(font-size, 320px, 599px, 9px, 10px);
              @include fluid-type(line-height, 320px, 599px, 11.72px, 13px);
              letter-spacing: 0.04rem;
            }
            p,
            a {
              font-size: 12px;
              line-height: 15.62px;
            }

            // a {
            //   font-size: 12px;
            //   line-height: 15.62px;
            // }
          }
        }
      }

      .section-breaker {
        @include fluid-type(font-size, 320px, 599px, 11px, 14px);
        @include fluid-type(line-height, 320px, 599px, 14.32px, 15.62px);
        @include fluid-type(margin-bottom, 320px, 599px, 16px, 24px);

        letter-spacing: 0.08em;
      }
    }
    .doc-breaker {
      // @include fluid-type(font-size, 600px, 904px, 14px, 20px);
      // @include fluid-type(line-height, 600px, 904px, 15.62px, 26.04px);
      letter-spacing: 0.08rem;
      font-size: 11px;
      line-height: 14.32px;
    }

    .rating-section-container {
      padding: 0;
      width: 100%;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 50px;
      .section-breaker {
        // @include fluid-type(font-size, 600px, 904px, 14px, 20px);
        // @include fluid-type(line-height, 600px, 904px, 15.62px, 26.04px);
        letter-spacing: 0.08rem;
        font-size: 11px;
        line-height: 14.32px;
      }
      .rating-section-content {
        @include fluid-type(column-gap, 600px, 904px, 20px, 36px);
        flex-direction: column;
        align-items: center;
        .rating-left {
          width: 100%;

          // max-width: 21.438rem;

          .rating-flex-container {
            .sigil-holder {
              align-items: center;
              .my-sigil {
                margin-top: 0;
                width: 160px;
                height: 160px;
              }
              .composite-score-container {
                margin: 0 5px 0 0;

                @include fluid-type(height, 600px, 904px, 134px, 166px);
                @include fluid-type(margin-right, 600px, 699px, 0px, 20px);

                section {
                  .legend-number,
                  .legend-number-0 {
                    // width: fit-content;
                    // @include fluid-type(width, 600px, 904px, 20.3px, 30px);

                    // @include fluid-type(font-size, 600px, 904px, 12px, 14px);
                  }
                  .comp-grade-container {
                    // @include fluid-type(width, 600px, 904px, 36px, 50px);

                    p {
                      @include fluid-type(font-size, 600px, 904px, 16px, 20px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .modal-intro-paragraph {
    margin-bottom: 0;
  }
  .data-table-modal-link {
    color: $BLUE;
  }
  .factors-title-container-left {
    padding: 0 0 4px 0;
    border-bottom: 1px solid rgba(238, 238, 238, 1);

    .first-grouping {
      // max-width: 266px;
      // width: 100%;
      @include fluid-type(width, 320px, 599px, 194px, 340px);

      h4 {
        letter-spacing: 0.02rem;

        @include fluid-type(font-size, 600px, 904px, 10px, 12px);
        @include fluid-type(line-height, 600px, 904px, 13px, 16px);
      }
    }
    .fact-con-score {
      width: 40px;
      justify-content: flex-end;
      letter-spacing: 0.02rem;
    }
    .fact-con-weight {
      letter-spacing: 0.02rem;
      width: 50px;
      min-width: 50px;
      margin-left: 44px;
      justify-content: flex-start;

      @include fluid-type(font-size, 600px, 904px, 10px, 12px);
      @include fluid-type(line-height, 600px, 904px, 13px, 16px);
    }
  }

  .rating-calc-button {
    @include fluid-type(font-size, 600px, 904px, 12px, 16px);
  }
  .factors-considered {
    // height:;
    padding: 0;
    background-color: $WHITE;
    height: fit-content;
    margin-bottom: 12px;
    // padding: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: $WHITE;
    border: 0.5px solid $DARK_GRAY;
    justify-content: center;

    .factor-info-section {
      // min-height: 30px;
      height: 30px;
      height: fit-content;
      flex-direction: column;
      .ft-exp-mobile {
        font-size: 10px;
        padding: 12px 8px;
        .ft-open-title {
          line-height: 13.02px;
          height: fit-content;
          text-align: left;
          font-weight: 400;
          color: #74757c;
          margin-bottom: 4px;
        }

        div {
          line-height: 18px;
          font-weight: 400;
          font-size: 12px;
        }

        .factor-history-container {
          gap: 8px;

          .factor-history-table {
            margin-bottom: 16px;
          
            .factor-history-table-headers {
              .factor-history-header-table-column {
                padding: 8px 12px;
                gap: 4px; 
                font-size: 10px;
              }
          
              .factor-history-table-date-column {
              }
              .factor-history-table-score-column{
              }
              .factor-history-table-event-column{
              }
              .rating-updated-date-icon{
                width: 16px;
                height: 16px;
              }
            }
          
            .factor-history-table-body {
              .factor-history-body-table-row {
              }
          
              .factor-history-body-table-column {
                gap: 10px;
                padding: 12px 12px;
              }
          
              .factor-history-table-date-column {
                height: auto;
              }
              .factor-history-table-score-column {
                height: auto;
              }
              .factor-history-table-event-column {
                height: auto;
              }
            }
          }
        }
      }
      .factor-bar {
        display: flex;
        padding: 8px 0 8px 8px;
        height: 30px;
        width: 100%;
        align-items: center;
        position: relative;
        justify-content: space-between;
        border-bottom: 0.5px solid transparent;

        label {
          @include fluid-type(width, 320px, 599px, 210px, 390px);
          max-width: 390px;
        }
        input {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .factor_bar_border {
        border-bottom: 0.5px solid black;
        // height: 22px;
      }
      .factor-down-arrow {
        min-width: 24px;
        max-width: 24px;
        width: 24px;
        padding: 0;
        height: 30px;
        background-image: url('../../assets/down-arrow.svg');
        // margin-left: 6px;
        background-position: center;
        background-repeat: no-repeat;
        // background-size: contain;
        background-color: transparent;
        border: none;
      }
      label {
        // width: 196px;
        // max-width: 266px;
        // @include fluid-type(width, 600px, 904px, 139px, 266px);

        align-items: center;

        .factor-progress-container {
          margin: 0;
          min-width: 62px;
          // max-width: 62px;
          // @include fluid-type(margin-left, 320px, 599px, 0px, 52px);
          @include fluid-type(margin-left, 320px, 599px, 0px, 102px);
          // @include fluid-type(width, 320px, 599px, 62px, 135px);

          // margin-left: auto;

          .factor-progress-container-styles {
          }
          .factor-progress-info {
            margin: 0 4px 0 0;
            width: 0;
          }
        }
        .factor-label {
          min-width: 110px;
          max-width: 110px;
          // margin-right: 8px;
          display: flex;
          flex-wrap: nowrap;
          @include fluid-type(font-size, 600px, 904px, 10px, 16px);
          @include fluid-type(line-height, 600px, 904px, 13px, 20.83px);
        }
        .label-ep-number {
          min-width: 18px;
          text-align: left;
          @include fluid-type(font-size, 600px, 904px, 10px, 16px);
          @include fluid-type(line-height, 600px, 904px, 13px, 20.83px);
          // margin-left: auto;
        }
      }

      .factor-weight-number {
        display: flex;
        width: 30px;
        align-items: center;
        height: fit-content;
        @include fluid-type(margin-left, 320px, 599px, 12px, 115px);
        @include fluid-type(font-size, 600px, 904px, 10px, 16px);
        @include fluid-type(line-height, 600px, 904px, 13px, 20.83px);
      }
    }
    .factor-progress-container {
      // max-width: 266px;
      @include fluid-type(width, 600px, 904px, 139px, 266px);
    }
  }

  .add-factors-div-container {
    .add-factors-section {
      margin-bottom: 12px;
      border-bottom: 1px solid rgba(238, 238, 238, 1);
      padding: 0 0 4px 0;

      .add-factors {
        width: fit-content;
        // height: 18px;
        @include fluid-type(font-size, 600px, 904px, 10px, 12px);
        @include fluid-type(line-height, 600px, 904px, 13px, 16px);
      }
    }
  }

  .document-section-holder {
    padding: 0;

    .documents-container {
      flex-wrap: wrap;
      gap: 20px;
      .document-button {
        flex-direction: row;
        gap: 12px;
        min-width: 258px;
        width: 100%;
        height: fit-content;
        padding: 12px;
        justify-content: flex-start;

        .document-icon {
          width: 28px;
          height: 36px;
        }
        .doc-info-container {
          align-items: flex-start;
          gap: 0;
          .document-name {
            font-size: 12px;
            line-height: 15.62px;
            text-align: left;
          }
          .document-date {
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 0.08rem;

            // @include fluid-type(font-size, 600px, 904px, 12px, 16px);
            // @include fluid-type(line-height, 600px, 904px, 15.62px, 20.83px);
          }
        }
      }
    }
  }
  .modal-intro-paragraph {
    // margin-bottom: 0;
  }
  .section-breaker {
    // line-height: 1.465rem;
    // @include fluid-type(font-size, 905px, 1239px, 18px, 20px);
  }

  .tag-container {
    flex-direction: column;
    row-gap: 6px;

    .tag-header-divider {
      // @include fluid-type(margin-right, 600px, 904px, 16px, 24px);
      // @include fluid-type(margin-left, 600px, 904px, 16px, 24px);
    }
    .tag-header {
      gap: 5px;
      @include fluid-type(font-size, 320px, 599px, 9px, 10px);
      @include fluid-type(line-height, 320px, 599px, 11.72px, 13px);
      letter-spacing: 0.04rem;

      // text {
      //   text-align: center;
      //   background-color: red;
      // }

      span {
        font-size: 12px;
        @include fluid-type(line-height, 320px, 599px, 15.62px, 16px);
      }
    }
  }

  .basic-info-title {
    // @include fluid-type(margin-bottom, 600px, 904px, 12px, 24px);
    h4 {
      // @include fluid-type(font-size, 600px, 904px, 10px, 12px);
      // @include fluid-type(line-height, 600px, 904px, 13.02px, 15.62px);
      // letter-spacing: 0.04rem;
      margin-bottom: 5px;
    }
    p,
    a {
      // @include fluid-type(font-size, 905px, 1239px, 12px, 16px);
      // @include fluid-type(line-height, 600px, 904px, 16px, 21px);
      margin-bottom: 0;
    }
  }

  .cta-container {
    @include fluid-type(width, 320px, 599px, 286px, 307px);
    padding: 20px 24px;

    .cta-emphasis-text {
      @include fluid-type(font-size, 320px, 599px, 11px, 14px);
      margin-bottom: 4px;
    }

    .cta-sub-text {
      font-weight: 400;
      text-align: center;
      @include fluid-type(font-size, 320px, 599px, 11px, 12px);
      @include fluid-type(margin-bottom, 320px, 599px, 16px, 20px);
    }

    .cta-card-btn {
      font-size: 12px;
      padding: 8px 24px;
      line-height: 15.62px;
    }
  }

  .footer {
    @include fluid-type(margin-top, 320px, 599px, -72.5px, -75px);
    padding: 220px 32px 30px 32px;
    // padding-right: 32px;
    .footer-content {
      .footer-upper {
        flex-direction: column;
        margin-bottom: 12px;

        .footer-arbilex {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          width: 100%;
          justify-content: center;
          .footer-text-container {
            display: flex;
            justify-content: space-between;

            // flex-direction: column;

            .footer-right-sm-container {
              display: flex;
              flex-direction: column;
              width: fit-content;

              .footer-text-section {
                width: fit-content;

                .footer-text-elements {
                  display: flex;
                  width: fit-content;
                }
              }
            }
          }

          .footer-logo {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            .logoMark {
              width: 26px;
              height: 26px;
              margin-right: 1rem;
            }
            .logo-text {
              font-size: 24px;
              padding: 0;
              line-height: 20px;
              vertical-align: bottom;
            }
          }
          .footer-socials {
            margin: 12px 0;

            .footer-socials-list {
              list-style-type: none;
            }
          }
          .footer-text-elements {
            font-size: 12px;
          }
        }
        .footer-links-container {
          display: flex;
          width: 40%;
          margin: 16px 0 12px 0;
        }
        .footer-text-section {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: fit-content;
          .footer-text-title {
            font-size: 12px;
            margin-bottom: 0;
          }
          .footer-text-elements {
            margin: 0;
          }
        }
      }
      .footer-text-elements {
        font-size: 10px;
        // @include fluid-type(font-size, 905px, 1239px, 12px, 16px);
      }
    }
  }
  .footer .footer-content .footer-lower {
    flex-direction: column;
    align-items: center;

    .footer-socials {
      // margin: 12px 0;

      .footer-socials-list {
        list-style-type: none;
        display: flex;
        padding: 0;
        width: fit-content;
        gap: 24px;
      }
    }
  }
  .footer-modal-link,
  .footer-lower-copyright {
    font-size: 10px;
  }
  .footer .footer-content .footer-lower .footer-lower-copyright {
    font-size: 10px;
    width: fit-content;
  }

  @media (min-width: 416px) and (max-width: 599px) {
    .footer-text-container {
      .footer-text-section {
        justify-content: space-between;
      }
      .footer-right-sm-container {
        row-gap: 12px;
      }
    }
  }
  @media (max-width: 416px) {
    .footer-text-container {
      flex-direction: column-reverse;
      row-gap: 12px;

      .footer-right-sm-container {
        row-gap: 12px;
      }
    }
  }
}

// Media query for factors styles
@media (min-width: 600px) and (max-width: 699px) {
  .factors-title-container-left {
    padding: 0;
  }
  .factors-considered {
    padding: 0;
    background-color: transparent;
  }
}

// Medium
@media (min-width: 600px) and (max-width: 767px) {
  .dropdown-container .nav-item.dropdown a {
    font-size: 12px;
    padding: 0.15rem 0.6rem;
  }

  .search-div {
    .css-b62m3t-container #search {
      padding-top: 4px;
      padding-bottom: 4px;
      height: 24px;
      font-size: 12px;
      line-height: 15.62px;
      background-color: #0090fc;
      border-color: #2c2e30;
      border-radius: 0.5rem;
      border-width: 0.1;
    }

    //search-party
    .css-cpryk8-menu {
      width: max-content;
      max-width: 70vh !important;
    }
    .css-1xc3v61-indicatorContainer {
      padding: 0px 6px !important;
    }
    .css-15lsz6c-indicatorContainer {
      padding: 0px 6px !important;
    }

    .css-13cymwt-control {
      @include fluid-type(height, 600px, 767px, 24px, 36px);
    }

    .css-t3ipsp-control {
      @include fluid-type(height, 600px, 767px, 24px, 36px);
    }

    .css-1fdsijx-ValueContainer {
      @include fluid-type(font-size, 600px, 767px, 12px, 14px);
    }

    .css-hlgwow {
      @include fluid-type(font-size, 600px, 767px, 12px, 14px);
    }

    .css-10wo9uf-option {
      @include fluid-type(font-size, 600px, 767px, 12px, 14px);
    }
    .css-d7l1ni-option {
      @include fluid-type(font-size, 600px, 767px, 12px, 14px);
    }
    .css-tr4s17-option {
      @include fluid-type(font-size, 600px, 767px, 12px, 14px);
    }
    .css-1dimb5e-singleValue {
      @include fluid-type(font-size, 600px, 767px, 12px, 14px);
    }
    .css-ptmv87-container {
      @include fluid-type(line-height, 600px, 767px, 15.62px, 24px);
    }

    .css-1u9des2-indicatorSeparator {
      margin: calc(0.88vw) 0px !important;
    }
  }

  .search-icon {
    @include fluid-type(width, 600px, 767px, 14px, 16px);
  }

  .sort-icon {
    @include fluid-type(height, 600px, 767px, 16px, 20px);
    @include fluid-type(width, 600px, 767px, 15px, 19px);
  }

  .modal-mega-container.modal-90w {
    .modal-content {
      max-height: 95vh;
    }
  }

  #sort-by-dropdown {
    // padding
    @include fluid-type(padding-top, 600px, 767px, 4px, 6px);
    @include fluid-type(padding-bottom, 600px, 767px, 4px, 6px);
    @include fluid-type(padding-left, 600px, 767px, 12px, 16px);
    @include fluid-type(padding-right, 600px, 767px, 12px, 16px);

    @include fluid-type(height, 600px, 767px, 24px, 36px);
    // @include fluid-type(width, 600px, 767px, 85px, 107px);
    @include fluid-type(font-size, 600px, 767px, 12px, 14px);
    @include fluid-type(line-height, 600px, 767px, 15.62px, 24px);
  }

  .dropdown-menu {
    @include fluid-type(font-size, 600px, 767px, 12px, 14px);
  }

  .filter-sort-container {
    height: fit-content;
    .dropdown-container {
      height: fit-content;
    }
  }
  .ref-holder-container {
    .filter-button {
      // padding
      @include fluid-type(padding-top, 600px, 767px, 4px, 6px);
      @include fluid-type(padding-bottom, 600px, 767px, 4px, 6px);
      @include fluid-type(padding-left, 600px, 767px, 12px, 16px);
      @include fluid-type(padding-right, 600px, 767px, 12px, 16px);

      @include fluid-type(height, 600px, 767px, 24px, 36px);
      @include fluid-type(width, 600px, 767px, 85px, 107px);
      @include fluid-type(font-size, 600px, 767px, 12px, 14px);
      @include fluid-type(line-height, 600px, 767px, 15.62px, 24px);
    }
  }

  .dropdown-container .nav-item.dropdown a {
    @include fluid-type(font-size, 600px, 767px, 12px, 14px);
  }

  .stats-paragraph {
    @include fluid-type(font-size, 600px, 767px, 8px, 10px);
    font-weight: 700;

    span {
      @include fluid-type(font-size, 600px, 767px, 12px, 14px);
    }
  }

  .last-stat-p {
    @include fluid-type(margin-bottom, 600px, 767px, 12px, 8px);
  }

  #rating-updated-tooltip {
    @include fluid-type(max-width, 600px, 904px, 480px, 600px);
    @include fluid-type(font-size, 600px, 904px, 14px, 16px);
    
    .rating-updated-info-title{
      @include fluid-type(font-size, 600px, 904px, 14px, 16px);
    }
  }

  .single-case-card {
    flex-direction: column;

    .single-card-info {
      .image-tags {
        margin-left: -4px;
        .status-tag,
        .secondary-tag {
          font-size: 10px;
          padding: 2px 6px;
          line-height: 1rem;
          border-radius: 0.5rem;
          text-align: center;
        }
        .rating_tag:before {
          border: 3px solid;
          border-radius: 7px;
        }
        .uncertainty_tag:before {
          border-radius: 7px;
        }
      }
    }

    .single-card-info {
      padding: 12px;

      .single-card-container {
        .rating-updated-tag-container {
          .rating-updated-tag{
            @include fluid-type(font-size, 600px, 904px, 12px, 16px);
  
            .rating-updated-info-icon {
              @include fluid-type(max-width, 600px, 904px, 14px, 18px);
              @include fluid-type(max-height, 600px, 904px, 14px, 18px);
            }
          }
          .rating-updated-tooltip-popup-container{
            @include fluid-type(max-width, 600px, 904px, 480px, 600px);
            @include fluid-type(font-size, 600px, 904px, 14px, 16px);
          }
          .rating-updated-info-title{
            @include fluid-type(font-size, 600px, 904px, 14px, 16px);
          }
        }
        .single-card-stats {
          @include fluid-type(margin-bottom, 600px, 767px, 12px, 4px);

          row-gap: 0;
          column-gap: 16px;
          max-height: fit-content;
        }
        .view-case-link-container {
          margin: 0;
          @include fluid-type(font-size, 600px, 767px, 12px, 14px);
        }
      }
    }
    .single-card-date {
      font-size: 12px;
      font-weight: 400;
    }
    .card-image-container {
      height: 40px;
      width: 100%;

      .card-image {
        width: 100%;
        border-radius: 5px 5px 0 0;

        .private-card-caption-5 {
          writing-mode: revert;
          font-size: 32px;
          border-right: 0px;
          border-bottom: 2px solid;
        }
      }

      .image-tags {
        height: 100%;
        align-items: center;

        .secondary-tag,
        .status-tag {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .listing-menu {
    display: none;
  }

  .nav-menu {
    display: none;
  }
  .center-nav-holder {
    position: relative;
    .logo-link {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
    }
    .user-menu-dropdown {
      position: absolute;

      right: 32px;
    }
  }
  .to-card-link {
    @include fluid-type(min-height, 600px, 767px, 63px, 65px);

    line-height: 20.48px;
    margin-bottom: 8px;
    a {
      @include fluid-type(font-size, 600px, 767px, 16px, 18px);
      @include fluid-type(line-height, 600px, 767px, 20px, 20.5px);
      height: 60px;
      width: 100%;
    }
  }

  .masonry-layout-holder {
    padding-top: 14px;
  }
  .center-filter-container {
    padding-top: 12px;
  }
  .masonry-container,
  .center-filter-container,
  .center-nav-holder {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

// Single Case Style - MEDIUM
@media (min-width: 600px) and (max-width: 904px) {
  .single-case-body-container {
    padding: 16px 16px 0 16px;

    .basic-case-information {
      margin: 0;
      padding: 0 16px;

      .rating-updated-tag-container {
        .rating-updated-tag{
          @include fluid-type(font-size, 600px, 904px, 14px, 16px);

          .rating-updated-info-icon {
            @include fluid-type(max-width, 600px, 904px, 14px, 18px);
            @include fluid-type(max-height, 600px, 904px, 14px, 18px);
          }
        }
        .rating-updated-tooltip-popup-container{
          @include fluid-type(max-width, 600px, 904px, 480px, 600px);
          @include fluid-type(font-size, 600px, 904px, 14px, 16px);
        }
        .rating-updated-info-title{
          @include fluid-type(font-size, 600px, 904px, 14px, 16px);
        }
      }
      .single-link-container {
        p {
          line-height: 22.4px;
          font-size: 14px;

          // @include fluid-type(font-size, 905px, 1239px, 14px, 18px);
        }
        .back-listing-link {
          div {
            display: none;
            // @include fluid-type(font-size, 905px, 1239px, 14px, 18px);
          }
        }
      }
      .case-title {
        @include fluid-type(font-size, 600px, 904px, 24px, 36px);
        @include fluid-type(line-height, 600px, 904px, 30.72px, 46.08px);
        letter-spacing: 0.64px;
        @include fluid-type(margin-bottom, 600px, 904px, 24px, 32px);
      }
      .case-tags {
        height: fit-content;
        @include fluid-type(margin-bottom, 600px, 904px, 16px, 24px);
        margin-top: 24px;
        letter-spacing: 1px;

        div {
          @include fluid-type(font-size, 600px, 904px, 10px, 12px);
          padding: 4px 8px;
          height: auto;
        }
      }
      .case-basic-info {
        @include fluid-type(margin-top, 600px, 904px, 12px, 24px);
        @include fluid-type(margin-bottom, 600px, 904px, 24px, 28px);
        // margin-bottom: 24px;

        .basic-info-container {
          .counsel-holder {
            @include fluid-type(margin-bottom, 600px, 904px, 12px, 24px);
          }
        }
      }

      .section-breaker {
        @include fluid-type(font-size, 600px, 904px, 14px, 18px);
        @include fluid-type(line-height, 600px, 904px, 15.62px, 23.44px);
        letter-spacing: 0.08em;
      }
    }
    .doc-breaker {
      @include fluid-type(font-size, 600px, 904px, 14px, 20px);
      @include fluid-type(line-height, 600px, 904px, 15.62px, 26.04px);
      letter-spacing: 0.08em;
    }

    .rating-section-container {
      padding: 16px;
      width: 100%;
      // @include fluid-type(margin-bottom, 905px, 1239px, 24px, 72px);

      .section-breaker {
        @include fluid-type(font-size, 600px, 904px, 14px, 20px);
        @include fluid-type(line-height, 600px, 904px, 15.62px, 26.04px);
        letter-spacing: 0.08rem;
        // font-size: 20px;
      }
      .rating-section-content {
        .rating-right {
          .factor-container {
            .factor-label {
              @include fluid-type(font-size, 600px, 904px, 10px, 12px);
              @include fluid-type(line-height, 600px, 904px, 13.02px, 15.62px);
              letter-spacing: 0.08rem;
              // @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
            }
          }
        }
      }
    }
  }

  .rating-section-content {
    @include fluid-type(column-gap, 600px, 904px, 20px, 32px);

    .rating-left {
      // width: 100%;
      @include fluid-type(width, 600px, 904px, 189px, 339px);

      // max-width: 21.438rem;

      .rating-flex-container {
        .sigil-holder {
          align-items: center;

          .my-sigil {
            margin-top: 0;
            width: 160px;
            height: 160px;
          }
          .composite-score-container {
            margin: 4px 0 0 32px;
            @include fluid-type(height, 600px, 904px, 134px, 166px);
            @include fluid-type(margin-right, 600px, 699px, 0px, 20px);

            section {
              .legend-number,
              .legend-number-0 {
                // width: fit-content;
                @include fluid-type(width, 600px, 904px, 20.3px, 30px);

                @include fluid-type(font-size, 600px, 904px, 12px, 14px);
              }
              .comp-grade-container {
                @include fluid-type(width, 600px, 904px, 36px, 50px);

                p {
                  @include fluid-type(font-size, 600px, 904px, 16px, 20px);
                }
              }
            }
          }
        }
      }
    }
    .rating-right {
      // width: 258px;
      @include fluid-type(width, 600px, 904px, 327px, 470px);

      padding-left: 0;
      .rating-holder {
        .factor-container {
          .factor-content,
          .modal-intro-paragraph {
            @include fluid-type(font-size, 600px, 904px, 12px, 16px);
            @include fluid-type(line-height, 600px, 904px, 18px, 20.83px);

            // @include fluid-type(font-size, 905px, 1239px, 16px, 20px);
            // @include fluid-type(line-height, 905px, 1239px, 20.83px, 26.04px);
          }
        }
      }
    }
  }
  .factors-title-container-left {
    padding: 0 0 8px 0;

    .first-grouping {
      min-width: 139px;
      @include fluid-type(width, 600px, 904px, 139px, 266px);

      h4 {
        letter-spacing: 0.02rem;
        font-weight: 500;
        @include fluid-type(font-size, 600px, 904px, 10px, 12px);
        @include fluid-type(line-height, 600px, 904px, 13px, 16px);
      }
    }
    .fact-con-score {
      min-width: 50px;
      letter-spacing: 0.02rem;
      justify-content: flex-end;
    }

    .fact-con-weight {
      font-weight: 500;
      letter-spacing: 0.02rem;
      // min-width: 50px;
      @include fluid-type(font-size, 600px, 904px, 10px, 12px);
      @include fluid-type(line-height, 600px, 904px, 13px, 16px);
    }
  }
  // Media query for factors styles
  @media (min-width: 600px) and (max-width: 699px) {
    .factors-considered {
      padding: 0;
      background-color: transparent;
    }
  }
  .rating-calc-button {
    @include fluid-type(font-size, 600px, 904px, 12px, 16px);
  }
  .factors-considered {
    height: fit-content;
    margin-bottom: 24px;

    .factor-info-section {
      height: fit-content;
      label {
        // max-width: 266px;
        min-width: 139px;
        @include fluid-type(width, 600px, 904px, 139px, 266px);

        align-items: center;
        p {
          @include fluid-type(font-size, 600px, 904px, 10px, 16px);
          @include fluid-type(line-height, 600px, 904px, 13px, 20.83px);
        }
      }
      .factor-weight-number {
        display: flex;
        margin-left: 0;
        align-items: center;

        @include fluid-type(font-size, 600px, 904px, 10px, 16px);
        @include fluid-type(line-height, 600px, 904px, 13px, 20.83px);
      }
    }
    .factor-progress-container {
      @include fluid-type(width, 600px, 904px, 139px, 266px);
      .factor-progress-info {
        margin-left: 0;
      }
      .factor-progress-container-styles {
        height: 6px;
        // width: 100%;
        // min-width: 139px;
        @include fluid-type(width, 600px, 904px, 139px, 266px);

        .factor-progress-styles {
          width: 100%;
        }
      }
      // @include fluid-type(width, 600px, 904px, 139px, 266px);
      // max-width: 252px;
    }
  }

  .add-factors-div-container {
    .add-factors-section {
      padding: 0 0 8px 0;
      .add-factors {
        width: fit-content;
        font-weight: 500;
        letter-spacing: 0.02rem;
        @include fluid-type(font-size, 600px, 904px, 10px, 12px);
        @include fluid-type(line-height, 600px, 904px, 13px, 16px);
      }
    }
  }

  .document-section-holder {
    padding: 0 16px;

    .documents-container {
      flex-wrap: wrap;
      gap: 20px;
      .document-button {
        flex-direction: row;
        gap: 16px;
        min-width: 258px;
        width: 100%;
        height: fit-content;
        padding: 16px;
        justify-content: flex-start;

        .document-icon {
          width: 28px;
          height: 36px;
        }
        .doc-info-container {
          align-items: flex-start;
          row-gap: 4px;
          .document-name {
            @include fluid-type(font-size, 600px, 904px, 12px, 16px);
            line-height: 20px;
            text-align: left;
          }
          .document-date {
            @include fluid-type(font-size, 600px, 904px, 12px, 16px);
            @include fluid-type(line-height, 600px, 904px, 15.62px, 20.83px);
            letter-spacing: 0.08rem;
          }
        }
      }
    }
  }
  .modal-intro-paragraph {
    margin-bottom: 0;
  }
  .section-breaker {
    // line-height: 1.465rem;
    // @include fluid-type(font-size, 905px, 1239px, 18px, 20px);
  }

  .tag-container {
    .tag-header-divider {
      @include fluid-type(margin-right, 600px, 904px, 16px, 24px);
      @include fluid-type(margin-left, 600px, 904px, 16px, 24px);
    }
    .tag-header {
      @include fluid-type(font-size, 600px, 904px, 10px, 12px);
      @include fluid-type(line-height, 600px, 904px, 13.02px, 15.62px);
      letter-spacing: 0.04rem;

      span {
        @include fluid-type(font-size, 600px, 904px, 12px, 16px);
        @include fluid-type(line-height, 600px, 904px, 16px, 21px);
      }
    }
  }

  .basic-info-title {
    @include fluid-type(margin-bottom, 600px, 904px, 12px, 24px);
    h4 {
      @include fluid-type(font-size, 600px, 904px, 10px, 12px);
      @include fluid-type(line-height, 600px, 904px, 13.02px, 15.62px);
      letter-spacing: 0.04rem;
      margin-bottom: 4px;
    }

    a {
      @include fluid-type(font-size, 905px, 1239px, 12px, 16px);
      @include fluid-type(line-height, 600px, 904px, 16px, 21px);
      margin-bottom: 0;
    }
    p {
      @include fluid-type(font-size, 600px, 904px, 12px, 16px);
      @include fluid-type(line-height, 600px, 904px, 16px, 21px);
      margin-bottom: 0;
    }
  }

  .source {
    a {
      @include fluid-type(font-size, 600px, 904px, 12px, 16px);
      @include fluid-type(line-height, 600px, 904px, 16px, 21px);
      margin-bottom: 0;
    }
  }

  .cta-container {
    @include fluid-type(width, 600px, 904px, 308px, 583px);
    @include fluid-type(padding-bottom, 600px, 904px, 20px, 36px);
    @include fluid-type(padding-top, 600px, 904px, 20px, 36px);
    @include fluid-type(padding-left, 600px, 904px, 24px, 72px);
    @include fluid-type(padding-right, 600px, 904px, 24px, 72px);

    .cta-emphasis-text {
      @include fluid-type(font-size, 600px, 904px, 14px, 20px);
      @include fluid-type(margin-bottom, 600px, 904px, 4px, 8px);
      line-height: 18.23px;
    }

    .cta-sub-text {
      @include fluid-type(font-size, 600px, 904px, 12px, 16px);
      min-width: 260px;
      line-height: 15.62px;
      text-align: center;
      @include fluid-type(margin-bottom, 600px, 904px, 20px, 36px);

      font-weight: 400;
    }

    .cta-card-btn {
      @include fluid-type(padding-bottom, 600px, 904px, 8px, 12px);
      @include fluid-type(padding-top, 600px, 904px, 8px, 12px);
      @include fluid-type(padding-left, 600px, 904px, 24px, 36px);
      @include fluid-type(padding-right, 600px, 904px, 24px, 36px);
      @include fluid-type(font-size, 600px, 904px, 12px, 16px);
    }
  }

  .footer {
    @include fluid-type(margin-top, 600px, 904px, -75.5px, -101px);

    padding: 220px 32px 60px 32px;
    // padding-right: 32px;
    .footer-content {
      .footer-upper {
        .footer-text-section {
          .footer-text-title {
            margin-bottom: 12px;
          }
        }
      }
      .footer-text-elements {
        @include fluid-type(font-size, 905px, 1239px, 12px, 16px);
      }
    }
  }
}

// Filter Menu responsive query
@media (min-width: 600px) and (max-width: 676px) {
  .modal-mega-container.modal-90w .modal-content {
    width: 90vw;
    max-width: 450px;
  }
  .case-type-container {
    flex-direction: column;
    font-size: 14px;
  }
  #filter-container {
    .case-type-section {
      .filter-title {
        font-size: 16px;
      }
    }
  }
  .date-overlay {
    input {
      font-size: 14px;
    }
  }

  #filter-venue-dropdown {
    font-size: 14px;
  }
  .min-max-container {
    font-size: 14px;
  }

  .right-checkbox-spacing {
    margin-top: 8px;
    display: flex;
    position: relative;
    left: auto;
  }

  .filter-range-separator {
    display: none;
  }

  .long-section {
    .case-type-container {
      row-gap: 10px;
    }
  }

  .dropdown-menu.show {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 904px) {
  // .bottom-card-container {
  //   display: flex;
  //   flex-direction: column;
  // }

  .search-div {
    //search-party
    .css-1fdsijx-ValueContainer {
      font-size: 18px;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }

    .css-hlgwow {
      font-size: 18px;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }

    .css-10wo9uf-option {
      font-size: 18px !important;
    }
    .css-tr4s17-option {
      font-size: 18px !important;
    }
    .css-d7l1ni-option {
      font-size: 18px !important;
    }
    .css-13cymwt-control {
      height: 2.25em !important;
    }

    .css-t3ipsp-control {
      height: 2.25em !important;
    }
  }

  .dropdown-container .nav-item.dropdown a {
    font-size: 18px;
  }

  #sort-by-dropdown {
    font-size: 18px;
  }

  .sort-icon {
    width: 22px;
    height: 23px;
  }

  .masonry-layout-holder,
  .center-filter-container {
    padding-top: 1rem;
  }

  .modal-mega-container.modal-90w {
    .modal-content {
      max-height: 95vh;
    }
  }
  .dropdown-menu {
    @include fluid-type(font-size, 600px, 767px, 14px, 16px);
  }
  .single-card-container {
    justify-content: space-between;
  }
  .single-case-card {
    max-width: 680px;
  }
  .card-image-container {
    max-width: 150px;

    .card-image {
      max-width: none;
    }
  }
  .to-card-link {
    a {
      font-size: 32px;
    }
  }
  .masonry-container {
    flex-direction: column;
    max-width: 680px;
    .column-holder {
      width: 100%;
    }
  }

  .single-card-info {
    max-width: 100%;
    flex-grow: 1;
  }

  .center-nav-holder {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .filter-sort-container {
    max-width: 680px;
  }
  .fade-tab-container {
    max-width: 380px;

    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 97%,
      rgba(0, 0, 0, 0)
    );
  }
}

// Starts Quickfilter tab fade out
// @media (min-width: 905px) and (max-width: 1239px) {
//   .masonry-container,
//   .center-filter-container,
//   .center-nav-holder {
//     width: 52.5rem;
//   }
// }

@media (min-width: 905px) and (max-width: 1239px) {
  .search-div {
    //search-party
    .css-1fdsijx-ValueContainer {
      font-size: 14px;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }

    .css-hlgwow {
      font-size: 14px;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }

    .css-10wo9uf-option {
      font-size: 14px !important;
    }
    .css-tr4s17-option {
      font-size: 14px !important;
    }
    .css-d7l1ni-option {
      font-size: 14px !important;
    }
    .css-13cymwt-control {
      height: 2.25em !important;
    }

    .css-t3ipsp-control {
      height: 2.25em !important;
    }
  }

  .dropdown-container .nav-item.dropdown a {
    font-size: 14px;
  }

  #sort-by-dropdown {
    font-size: 14px;
  }

  .sort-icon {
    width: 18px;
    height: 19px;
  }

  // LISTING STYLES
  .single-card-container {
    justify-content: space-between;
  }
  .modal-mega-container.modal-90w {
    .modal-content {
      max-height: 95vh;
    }
  }

  .masonry-layout-holder,
  .main-nav,
  .center-filter-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .center-filter-container,
  .masonry-layout-holder {
    padding-top: 1.5rem;
  }

  .card-image {
    width: 72px;
  }

  .filter-button {
    width: 107px;
    font-size: 14px;

    .filter-svg {
      width: 16px;
    }
  }
  .stats-paragraph span {
    @include fluid-type(font-size, 905px, 1239px, 16px, 20px);
  }

  .single-card-date,
  .view-case-link {
    @include fluid-type(font-size, 905px, 1239px, 14px, 16px);
  }

  @media (min-width: 905px) and (max-width: 994px) {
    .single-card-stats {
      flex-direction: column;
    }
  }
  .single-card-stats {
    @include fluid-type(margin-bottom, 905px, 1239px, 12px, 16px);
    @include fluid-type(row-gap, 905px, 1239px, 12px, 16px);
  }

  .status-tag {
    @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
    padding: 0.25rem 0.5rem;
    line-height: 0.976rem;
    letter-spacing: 1px;
  }

  .to-card-link {
    // @include fluid-type(height, 905px, 1239px, 120px, 120px);
    // @include fluid-type(margin-bottom, 905px, 1239px, 20px, 24px);
    // min-height: 84px;
    // height: fit-content;
    margin-bottom: 16px;

    a {
      // line-height: 15px;
      @include fluid-type(height, 905px, 1239px, 116px, 116px);
      @include fluid-type(font-size, 905px, 1239px, 24px, 28px);
      // @include fluid-type(line-height, 905px, 1239px, 1.76rem, 1.95rem);
    }
  }

  .status-tag {
    @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
  }

  .view-case-link-container {
    @include fluid-type(margin-top, 905px, 1239px, 0px, 8px);
  }

  // SINGLE CASE STYLES
  .single-case-body-container {
    padding: 24px 8px 0 8px;

    .basic-case-information {
      margin-right: 0;
      margin-left: 0;
      padding-right: 24px;
      padding-left: 24px;

      .single-link-container {
        p {
          @include fluid-type(font-size, 905px, 1239px, 14px, 18px);
        }
        .back-listing-link {
          div {
            @include fluid-type(font-size, 905px, 1239px, 14px, 18px);
          }
        }
      }
      .case-title {
        @include fluid-type(font-size, 905px, 1239px, 36px, 48px);
        line-height: 46.08px;
        // letter-spacing: 4%;
        @include fluid-type(margin-bottom, 1240px, 1439px, 32px, 60px);
      }
      .case-tags {
        margin-top: 24px;
        div {
          @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
        }
      }
      .case-basic-info {
        // margin-bottom: 24px;
        @include fluid-type(margin-bottom, 905px, 1239px, 24px, 72px);
      }
    }

    .rating-section-container {
      padding: 24px;
      @include fluid-type(margin-bottom, 905px, 1239px, 24px, 72px);

      .section-breaker {
        font-size: 20px;
      }
      .rating-section-content {
        .rating-right {
          .factor-container {
            .factor-label {
              @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
            }
          }
        }
      }
    }
  }
  .rating-flex-container {
    .add-factors-div-container {
      .add-factors-section {
        padding: 0 0 11px 0;

        .add-factors {
          letter-spacing: 0.1em;
          @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
        }
      }
    }
  }

  .rating-section-content {
    .rating-right {
      .factor-container {
        .factor-content,
        .modal-intro-paragraph {
          @include fluid-type(font-size, 905px, 1239px, 16px, 20px);
          @include fluid-type(line-height, 905px, 1239px, 20.83px, 26.04px);
        }
      }
    }
  }
  .factors-title-container-left {
    padding: 0 0 11px 0;
    margin-bottom: 24px;
    .first-grouping {
      @include fluid-type(width, 905px, 1239px, 267px, 340px);

      h4 {
        @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
      }
    }
    .fact-con-weight {
      margin-left: 20px;
      @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
    }
  }

  .factors-considered {
    .factor-info-section {
      label {
        @include fluid-type(width, 905px, 1239px, 267px, 339px);
        p {
          @include fluid-type(font-size, 905px, 1239px, 16px, 20px);
        }
      }
      .factor-weight-number {
        @include fluid-type(font-size, 905px, 1239px, 16px, 20px);
      }
    }
    .factor-progress-container {
      @include fluid-type(width, 905px, 1239px, 267px, 340px);

      // max-width: 267px;
    }
  }

  .document-section-holder {
    padding: 0 24px;
  }
  .section-breaker {
    line-height: 1.465rem;
    @include fluid-type(font-size, 905px, 1239px, 18px, 20px);
  }

  .tag-container {
    .tag-header {
      @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
      @include fluid-type(line-height, 905px, 1239px, 15.62px, 18.23px);

      span {
        @include fluid-type(font-size, 905px, 1239px, 16px, 20px);
      }
    }
  }

  .basic-info-title {
    h4 {
      @include fluid-type(font-size, 905px, 1239px, 12px, 14px);
      @include fluid-type(line-height, 905px, 1239px, 15.62px, 18.23px);
    }
    p,
    a {
      @include fluid-type(font-size, 905px, 1239px, 16px, 20px);
      margin-bottom: 0;
    }
  }

  .footer {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (min-width: 905px) {
  .factors-title-container-left {
    .first-grouping {
      // @include fluid-type(width, 905px, 1440px, 267px, 340px);
    }
  }

  .factor-progress-container {
    // @include fluid-type(width, 905px, 1239px, 267px, 340px);

    // @include fluid-type(width, 905px, 1131px, 244px, 340px);
    // width: 100%;
  }
}
// Large
@media (min-width: 1240px) and (max-width: 1439px) {
  // .masonry-layout-holder {
  //   padding-left: 3.75rem;
  //   padding-right: 3.75rem;
  // }

  #sort-by-dropdown {
    font-size: 18px;
  }

  .basic-case-information {
    // padding: 48px 60px;
    // max-width: 1120px;
    // margi

    .case-title {
    }
    .case-tags {
      @include fluid-type(margin-top, 1240px, 1439px, 32px, 48px);
    }
  }
  // .case-basic-info {
  //   margin-bottom: 60px;
  // }
  .rating-section-container {
    padding: 24px;
    // margin-left: 36px;
    // margin-right: 36px;
  }

  .single-case-body-container {
    padding: 3rem 36px 0 36px;
  }

  // .rating-section-container {
  //   margin-right: -36px;
  //   margin-left: -36px;
  //   padding: 24px;
  //   width: 1168px;
  // }
  .factors-title-container-left {
    margin-bottom: 24px;
    h4 {
      padding: 0 0 11px 0;
    }
  }
  .add-factors-section {
    margin-bottom: 24px;

    padding: 0 0 11px 0;

    .add-factors {
      letter-spacing: 0.1em;
    }
  }
}

@media (min-width: 1440px) {
  #sort-by-dropdown {
    font-size: 18px;
  }
}

// RESPONSIVE STYLES FOR 404, SIGNIN, SIGNUP, PASSWORD

@media (min-width: 320px) {
  .not-found-container {
    @include fluid-type(padding-bottom, 320px, 1440px, 30px, 0px);
    .nf-title {
      @include fluid-type(font-size, 320px, 1440px, 130px, 240px);
      @include fluid-type(margin-top, 320px, 1440px, 20px, 120px);
    }
  }
  .auth-background {
    .auth-container {
      form {
        .submit-button {
          @include fluid-type(padding-top, 320px, 1440px, 8px, 16px);
          @include fluid-type(padding-bottom, 320px, 1440px, 8px, 16px);
          @include fluid-type(padding-right, 320px, 1440px, 30px, 64px);
          @include fluid-type(padding-left, 320px, 1440px, 30px, 64px);
          @include fluid-type(font-size, 320px, 1440px, 14px, 16px);
        }
      }
      // padding: 8pxleft
    }
  }
}
