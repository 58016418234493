@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
}

.App {
  display: flex;
  flex-direction: column;
}

@import 'Auth/module';
@import 'Cards/module';
@import 'Layout/module';
@import 'Helpers/module';
@import 'Responsive/module';
@import 'Support/module';
@import 'Dash/module';
