.factors-title-container-left {
  display: flex;
  justify-content: space-between;
  margin: 1.313rem 0 1.5rem 0;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 11px;

  .first-grouping {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 340px;
  }

  .fact-con-title,
  .fact-con-score,
  .fact-con-weight {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #74757c;
  }
  .fact-con-title {
    // width: 23rem;
    text-align: left;
  }
  .fact-con-score {
    text-align: right;
  }
  .fact-con-weight {
    text-align: right;
    max-width: 64px;
    // width: 100%;
    justify-content: flex-end;
  }
}

.factors-considered {
  width: 100%;
  display: flex;
  flex-direction: column;
  // background-color: $FACTOR_GRAY;
  height: 3.625rem;
  // border-radius: 0.5rem;
  // padding: 0.5rem;
  position: relative;
  margin-bottom: 24px;

  .factor-progress-container {
    .factor-progress-info {
      max-width: 64px;
      // width: 100%;
      // margin-left: 20px;
    }
  }
  .factor-info-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 26px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.625rem;
    letter-spacing: 0em;

    p {
      margin: 0;
      text-align: center;
      // width: 4.875rem;
    }

    label {
      display: flex;
      width: 100%;
      // width: 22.5rem;
      max-width: 340px;

      cursor: pointer;

      p {
        margin: 0;
      }
      .factor-label {
        margin: 0 0 0 0.5rem;
        width: 100%;
        text-align: left;
      }
      .label-ep-number {
        text-align: right;
      }
    }
  }
}
.factor-weight-number {
  max-width: 64px;
  text-align: right;
  // width: 100%;f
  margin-left: 20px;
  justify-content: flex-end;

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.show_factor {
  display: none;
}

.no_show_factor {
  background-color: transparent;
}

.is_free {
  cursor: pointer;
  .factor-info-section {
    .label-ep-number {
      filter: blur(3px);
      text-align: right;
    }

    .free-label {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-right: 30px;

      .factor-label {
        width: 100%;
        margin-left: 8px;
        text-align: left;
      }

      .free-check {
        background-image: url('../../assets/lock.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 13px !important;
        min-width: 13px !important;
      }
    }
  }

  .factor-progress-container {
    filter: blur(3px);
    .factor-progress-container-styles {
      background-color: rgba(151, 151, 151, 0.4);
    }
  }
}

.add-factors-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 11px;

  div {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
  }
  .add-factors {
    margin-bottom: 0;
    // width: 16.25rem;
    width: 100%;
    min-width: 16.25rem;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.125rem;
    letter-spacing: 0.1em;
    text-align: center;
    color: #999999;

    // &:before {
    //   content: ' ';
    //   display: inline-block;
    //   width: 100%;
    //   height: 1px;
    //   background-color: #eeeeee;
    // }
  }
}
