.dash-bookmarks-container {
  width: 100%;
  max-width: 360px;
  display: flex;
  gap: 24px;
  .dash-bmk-title-holder {
    display: flex;
    justify-content: space-between;
    .dash-mod-title {
    }
    .bmk-see-all {
      display: flex;
      align-items: center;
      padding: 4px 13.6px 4px 8px;
      color: #1c1c1c;
      text-decoration: none;
      border: 1px solid #e8e8e8;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      font-size: 13px;
      line-height: 20px;

      .dash-bmk-arrow {
        height: 9.31px;
        width: 5.49px;
        margin-left: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url('../../assets/dash-bmk-arrow.svg');
      }
    }
  }

  .dash-bmk-numbers-holder {
    display: flex;

    .bmk-mid-section {
      flex: 1;
      .bmk-section-num {
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        color: #1c1c1c;
      }
      .bmk-section-title {
        color: #969696;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
      }
      .bmk-type-link {
        display: flex;
        flex-direction: column;
        gap: 16px;

        a {
          text-decoration: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #585757;

          .bmk-type-count {
            background: #969696;
            border-radius: 96px;
            padding: 0px 4px;
            display: inline-block;
            width: 22px;
            margin-right: 8px;
            color: #ffffff;

            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.04%;
          }

          .dash-bmk-arrow {
            height: 9.31px;
            width: 5.49px;
            margin-left: 15.5px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url('../../assets/dash-bmk-arrow.svg');
          }
        }
      }
    }
  }
}
