// use this class to help negate the problem with the child Row
// taking up more space than the parent due to negative margins
// from react-bootstrap Rows

// Removing autofill Styling
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   border: none;
//   background-color: inherit;
//   transition: background-color 5000s ease-in-out 0s;
// }

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

// custom col width
.col-md-1half, .col-sm-2half, .col-sm-3half, .col-md-4half, .col-md-5half, .col-md-6half, .col-md-7half, .col-sm-8half, .col-sm-9half, .col-md-10half, .col-md-11half{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .col-md-1half, .col-sm-2half, .col-sm-3half, .col-md-4half, .col-md-5half, .col-md-6half, .col-md-7half, .col-sm-8half, .col-sm-9half, .col-md-10half, .col-md-11half{
        float: left;
    }
    .col-md-1half { width: 12.5%; float: left; } // = 8,3333 + 4,16667
    .col-md-2half { width: 20.83333%; float: left;} // = 16,6666 + 4,16667
    .col-md-3half { width: 29.16667%; float: left;} // = 25 + 4,16667
    .col-md-4half { width: 37.5%; float: left;} // = 33,3333 + 4,16667
    .col-md-5half { width: 45.83333%; float: left;} // = 41,6667 + 4,16667
    .col-md-6half { width: 54.16667%; float: left;} // = 50 + 4,16667
    .col-md-7half { width: 62.5%; float: left;} // = 58,3333 + 4,16667
    .col-md-8half { width: 70.83333%; float: left;} // = 66,6666 + 4,16667
    .col-md-9half { width: 79.16667%; float: left;} // = 75 + 4,16667
    .col-md-10half { width: 87.5%; float: left;} // = 83,3333 + 4,16667
    .col-md-11half { width: 95.8333%; float: left;} // = 91,6666 + 4,16667
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@mixin fluid-type-height($properties, $min-vh, $max-vh, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-height: $min-vh) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vh - #{$min-vh}) / #{strip-unit($max-vh - $min-vh)});
    }
  }

  @media screen and (min-height: $max-vh) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}
