// @use '../../base/colors';
// @use '../..ase/typography' as t;
// @use '../../base/display' as d;

.masonry-layout-holder {
  width: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2.25rem;
}

.single-case-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  height: fit-content;
  margin: 20px 0;
  position: relative;
  transition: 0.2s ease-in-out;
  overflow: hidden;
}

.single-case-card:hover {
  border: 1px solid #2c2e30;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.14);
}

.single-case-card:first-child {
  margin-top: 0;
}

.single-card-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding: 0 1.5em 1.6875em 1.5em;
  padding: 0 24px 24px 24px;
  max-width: 490px;
  width: 100%;
}

.to-card-link {
  min-height: 120px;
  line-height: 41px;
  text-decoration: none;
  color: #222222;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 24px;
}

.underline-animation {
  text-decoration: none;
  background-image: linear-gradient($BLUE, $BLUE);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 3px;
  transition: background-size 0.3s;
}

.underline-animation:hover,
.underline-animation:focus {
  background-size: 100% 3px;
}

.single-card-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  .single-card-date {
    color: $BLUE;
    margin-bottom: 8px;
  }
}

.single-card-title {
  line-height: 1.95rem;
  font-size: 28px;
  color: #1f2126;
  font-family: Lora;
  font-weight: 600;
}

.single-card-title:hover {
  color: #222222;
}

.single-card-stats {
  display: flex;
  flex-direction: row;
  bottom: 0;
  width: 100%;
  row-gap: 16px;
  column-gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.stats-paragraph {
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  font-weight: 700;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 12px;
  color: #74757c;
  letter-spacing: 0.06rem;

  span {
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    font-size: 20px;
    color: #1f2126;
  }
}

.single-card-stats p:not(:last-child) {
  margin-bottom: 14px;
}

.view-case-link-container {
  margin-top: 16px;
  .view-case-link {
    text-decoration: none;
    color: $BLUE;
  }
}

.is_exclusive {
  @include FLEX_COLUMN;
  height: 42.1875em;
}

.card-image-container {
  // width: 100%;
  display: flex;
  position: relative;
}

.image-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  // position: absolute;
  // top: 0;
  margin-bottom: 12px;
}

.pre-card-container{
  background-color: $WHITE;
  color: $WHITE;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  height: 32px;
  width: 100%;    
  z-index: 1;
  display: flex;
  flex-direction: row;
}

.pre-card-concluded-text{
  padding: 6px 16px;
  width: fit-content;
  position: relative;
  background-color: $BLUE;
  border-radius: 0 0 8px 0;
  text-align: center;
}

.pre-card-concluded-background{
  width: 100%;
  height: 12px;
  position: relative;
  background-color: $BLUE;
  // top: calc(50% - 6px);
}

.card-container{
  display: flex;
  flex-direction: row;
  // border-radius: 8px;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  transition: 0.2s ease-in-out;
}

.card-image {
  width: 95px;
  // width: 100%;
  height: 100%;
  // border-radius: 8px 0px 0px 8px;
  object-fit: cover;
}

.exclusive_img_size {
  height: 10.625em;
}

.regular_content {
  padding-top: 24px;
}

.exclusive_content {
  height: 100%;
  padding-top: 0.875em;
  padding-bottom: 0;
  position: relative;
}

.exclusive_info {
  @include FLEX_COLUMN;
}

.days-left {
  line-height: 1.465em;
  font-weight: 400;
  font-size: 1.125em;
  text-align: center;
  justify-self: flex-end;
}
.exclusive-stats-container {
  @include FLEX_COLUMN;
  margin-top: 1.5rem;
  height: 9.375em;
  justify-content: space-evenly;
  border-top: 1px solid rgba(212, 212, 212, 1);
}
.exc-stats-paragraph {
  margin-bottom: 0;
  font-size: 1em;
  font-weight: 400;
  line-height: 21px;
}
.exc_speratation {
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  justify-self: flex-start;
  bottom: 14.3125em;
  max-width: 23.125rem;
}

.status-tag {
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
  font-weight: 700;
  border-radius: 0.625rem;
  margin: 4px;
  line-height: 1.139em;
  letter-spacing: 1px;
  /* height: 2.125rem !important; */
}

.accepting_status {
  background-color: $ACCPETING_BG;
  color: $GREEN;
}
.exclusive_status {
  background-color: $BLUE;
  color: rgba(255, 255, 255, 1);
}

.funded_status {
  background-color: #ebe7f6;
  color: #3a0ca3;
}

// .unknown_status {
//   background-color: rgba(230, 233, 236, 1);
//   color: #001d3d;
// }

.accepting_tag {
  background-color: $ACCPETING_BG;
  color: $GREEN;
}

.secondary_tag {
  color: #1a1a1a;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid black;
}

.exclusive_tag {
  background-color: $BLUE;
  color: rgba(255, 255, 255, 1);
  border: none !important;
}

.rating_tag {
  position: relative;
}

.rating_tag:before {
  background: none;
  border: 3px solid;
  border-radius: 9px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.rating_a:before {
  border-color: #2F5BEA !important;
}

.rating_b:before {
  border-color: #0090FC !important;
}

.rating_c:before {
  border-color: #5FBCFF !important;
}

.rating_d:before {
  border-color: #B1E4FF !important;
}


.uncertainty_tag {
  position: relative;
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0.9);
}

.uncertainty_tag:before {
  background: none;
  border: 3px solid $COMP_RED;
  border-radius: 9px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.single-card-header-container {
  display: flex;
  justify-content: space-between;
}

.private-card-icon-image {
  height: 28px;
}