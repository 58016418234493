.dash-reqs-container {
  display: flex;
  max-width: 1120px;
  width: 100%;
  padding: 30px 24px;

  .no-active-reqs {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    background-color: #f4f5f7;
    border: 2px dashed #000;
  }

  .dash-req-title-holder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .dash-mod-title {
    }
    .req-see-all {
      color: $BLUE;
      text-decoration: none;
      // border: 1px solid #e8e8e8;
      // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
      // border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      // padding: 4px 10.56px 4px 8px;

      .dash-bmk-arrow {
        height: 7.44px;
        width: 4.39px;
        margin-left: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url('../../assets/dash-bmk-arrow.svg');
      }
    }
  }
  .dash-request-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .masonry-layout-holder{
      padding: 0;
      .masonry-container {
        padding: 0;
      }
    }

    .dash-req-module {
      display: grid;
      position: relative;
      // flex-direction: column;
      min-height: 130px;
      background-color: #f4f6f7;
      padding: 24px 26px;
      border-radius: 8px;
    }
    .dash-req-progress {
      width: 64px;
      height: 64px;
      margin-right: 16px;
      background-color: cornflowerblue;
    }
    .dash-req-mod-header {
      .dash-req-uuid,
      .dash-req-date {
        color: #848383;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        margin-bottom: 4px;
      }
    }
    .dash-req-title {
      font-family: 'Lora';
      display: flex;
      text-decoration: none;
      color: #1c1c1c;
      font-size: 21px;
      font-weight: 600;
      line-height: 24px;
      align-items: center;
      margin-bottom: 12px;

      // .dash-bmk-arrow {
      //   width: 5.49px;
      //   height: 9.31px;
      //   margin-left: 10px;
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   background-position: center;
      //   background-image: url('../../assets/dash-bmk-arrow.svg');
      // }
    }
    .dash-req-body {
      display: flex;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #585757;
    }
  }
}
