$GREEN: #2c9f2c;
$BLUE: #2f5bea;
$WHITE: #ffffff;

$ACCPETING_BG: #eaf6ea;

$GRAPHITE: #2c2e30;
$DARK_GRAY: #4a4a4a;
$FACTOR_GRAY: #f4f5f7;
$LIGHT_GRAY: #6b7280;

$OFF_WHITE: #f3f4f6;
$OFF_BLACK: #1e222e;

$D8: #d8d8d8;
$D9: #d9d9d9;
$DARK2: #222222;

//COMPOSITE SCORE COLORS
$COMP_GREEN: #2c9f2c;
$COMP_YELLOW: #ffd204;
$COMP_ORANGE: #fc7f18;
$COMP_RED: #e54418;
$COMP_FONT: #65676f;

//Rating Color ArbiLex Blue
$COMP_ARBI_BLU_A: #2f5bea;
$COMP_ARBI_BLU_B: #0090fc;
$COMP_ARBI_BLU_C: #5fbcff;
$COMP_ARBI_BLU_D: #b1e4ff;

//Rating Color Cerulean
// $COMP_CERULEAN_A: #1D4C81;
// $COMP_CERULEAN_B: #2880A0;
// $COMP_CERULEAN_C: #58B4B9;
// $COMP_CERULEAN_D: #A7E3D7;
