:root {
  .Toastify__toast {
    border-radius: 5px;
    background: $BLUE;
    color: $WHITE;
    // top: 60px;
  }
}

.single-case-toast {
  .Toastify__progress-bar {
    --toastify-color-progress-light: transparent;
    // background-color: #b1e4ff !important;
  }
}

//   width: 90vw;
.modal-dialog {
  margin: 5vh auto;
  max-width: 90vw;
  //   height: 95vh;
  text-align: left;
  // width: 1000px !important;
  //   max-width: 90vw;

  .modal-content {
    padding: 3rem 4rem;

    .modal-close-button {
      cursor: pointer;
      float: right;
      font-size: 1.8rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    .modal-body {
      padding: 0;

      p {
        font-weight: 400;
      }
    }
  }
}

.modal-90w {
  display: flex;

  margin: 5% 5vw;
  width: 90vw;
}
// //   .modal-header {
// //     padding: 2rem 2rem  0 2rem;

// //     .modal-close-button {
// //       cursor: pointer;
// //       float: right;
// //       font-size: 1.8rem;
// //       position: absolute;
// //       // background-color: red;
// //       right: 1rem;
// //       top: 1rem;
// //     }
// //   }

// //   .modal-body {
// //     padding: 2rem;

// //     p {
// //       font-weight: 400;
// //       font-family: 'DM Sans', Helvetica, Arial, serif;
// //       font-size: 16px;
// //     }
// //   }
// // }
// .modal-dialog {
//   //   height: 90vh;
//   margin: 5vh auto;
//   //   width: 90vw;

//   .modal-content {
//     border: 0;
//     height: 90vh;
//     width: 90vw;
//     overflow-y: scroll;
//     margin: auto;
//     // height: 100%;
//     // width: 100%;
//   }

//   .modal-close-button {
//     cursor: pointer;
//     float: right;
//     font-size: 1.8rem;
//     position: absolute;
//     right: 1rem;
//     top: 1rem;
//   }

//   .modal-body {
//     height: 95%;
//     padding: 3rem 4rem;
//   }
// }
