.masonry-container {
  display: flex;
  // margin: 2.25rem 60px;
  justify-content: space-between;
  max-width: 1120px;
  width: 100%;
  align-self: space-between;
  gap: 20px;
}

.column-holder {
  width: 644px;
  // horizontal-gap: 32px;
}
