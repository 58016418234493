/* WEIGHTS */
@mixin WEIGHT_400 {
  font-weight: 400;
}
@mixin WEIGHT_500 {
  font-weight: 500;
}
@mixin WEIGHT_700 {
  font-weight: 700;
}

/* FONT SIZES PX => REM */
@mixin SIZE_12_REM {
  font-size: 0.75rem;
}
@mixin SIZE_14_REM {
  font-size: 0.875rem;
}
@mixin SIZE_18_REM {
  font-size: 1.125rem;
}

/* GENERAL SIZES */
$BIG_36: 2.25rem;
$BIG_48: 3rem;
