.case-source-link {
  align-items: center;
  height: fit-content;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  @include WEIGHT_400;
  font-size: 20px;
  color: #1f2126;
  width: fit-content;
}
.case-source-svg {
  height: 13px;
  width: 13px;
  margin-left: 5px;
  background-image: url('../../assets/external-link-branded.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.single-case-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 6.25rem 0 6.25rem;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
}

.basic-case-information {
  margin: 0 60px;
  max-width: 1120px;
  width: 100%;
  // padding: 3rem 10rem 0 10rem;
}

.rating-section-container {
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  padding: 2.25rem 3.75rem;
  // margin: 0px -3.75rem;
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  margin-bottom: 4.5rem;
}

.single-link-container {
  @include FLEX_ALIGN_CENTER;
  @include WEIGHT_400;
  @include SIZE_18_REM;
  justify-content: space-between;
  line-height: 1.8rem;

  .back-listing-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    div {
      @include FLEX_ALIGN_CENTER;
      @include WEIGHT_400;
      color: $DARK_GRAY;
    }
  }

  p {
    @include WEIGHT_400;
    color: $DARK_GRAY;
    margin: 0;
  }
}

.funding-status {
  margin-top: 0 !important;
}

.case-tags {
  @include SIZE_12_REM;
  @include FLEX_ALIGN_CENTER;
  gap: 8px;
  line-height: 0.976rem;
  letter-spacing: 0.06rem;
  justify-content: flex-start;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  height: 2.25rem;
  flex-wrap: wrap;
}

.case-title {
  font-family: Lora;
  @include WEIGHT_700;
  font-size: $BIG_48;
  letter-spacing: 0.04em;
  margin: 0 0 3.75rem 0; /*3.813rem*/
  line-height: 3.75rem;
}

.section-breaker {
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  padding-bottom: 8px;
  border-bottom: solid 0.1rem black;
  margin-bottom: 1.5rem;
  line-height: 1.628rem;
}

.case-base-info {
  display: flex;
  justify-content: space-between;
  height: 3.125rem;
  width: 100%;
  margin-bottom: 12px;
}

.tag-container {
  @include SIZE_12_REM;
  display: flex;
  justify-content: space-between !important;
}

.tag-header {
  line-height: 1.302rem;
  letter-spacing: 0.1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
  color: #74757c;
  text-transform: uppercase;

  .tag-header-title {
    font-weight: 700;
  }
}

.tag-header span {
  letter-spacing: normal;
  font-weight: 400;
  width: 100%;
  text-transform: none;
  font-size: 20px;
  color: #1f2126;
}

.tag-funding-status {
  margin-top: 0 !important;
  height: $BIG_36;
  align-self: flex-start;
  color: #001d3d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  font-size: 0.875rem;
  font-weight: 700 !important;
  background-color: rgba(0, 29, 61, 0.1);
  min-width: 5.938rem;
  padding: 0.25rem 1rem;
}

.concluded_status {
  background-color: #2f5bea;
  color: white;
}

.tag-funding-status.uncertainty_tag {
  background-color: rgba(255, 255, 255, 0.9);
}

.tag-header-divider {
  height: 100% !important;
  width: 1px !important;
  background-color: #eeeeee;
  margin-left: 36px;
  margin-right: 36px;
}

// What's this @david?
.TEST {
  height: 30px;
  width: 30px;
}

.is_accepting_tag {
  background-color: $ACCPETING_BG;
  color: $GREEN;
}

.is_offering_tag {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid black;
}

.status-bubble {
  font-size: 14px;
  background-color: rgba(0, 29, 61, 0.1);
  border-radius: 10px;
  padding: 2px 16px;
  color: rgba(0, 29, 61, 1) !important;
  font-weight: 700 !important;
}

.case-title-svg-container {
  width: 7.75em;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.back-arrow {
  // left: 8.063rem;
  // position: absolute;
  margin-right: 1.125rem;
  width: 0.8rem;
  height: 0.8rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../assets/back-arrow.svg');
}

.bookmark-svg,
.share-svg {
  background-repeat: no-repeat;
  background-size: contain;
  width: 3.125rem;
  transition: all 0.3s;
}

.share-svg {
  background-image: url('../../assets/print.svg');
  margin-left: 0.75em;
}

.share-svg:hover {
  background-image: url('../../assets/print-hover.svg');
  cursor: pointer;
}

.case-basic-info {
  margin-top: 1.5rem;
  margin-bottom: 4.5rem;
  width: 100%;

  .basic-info-container {
    display: flex;
    flex-basis: 35rem;
    flex-wrap: wrap;

    .counsel-holder {
      width: 100%;
      display: flex;
      margin-bottom: 1.5rem;

      .basic-info-title:first-child {
        margin-right: 6px;
      }
      .basic-info-title:last-child {
        margin-left: 6px;
      }
    }
  }
  .basic-info-container > .basic-info-title:first-child {
    margin-bottom: 1.5rem;
    width: 100%;
  }
}

.basic-info-title {
  width: 35rem;
}
.source {
  width: 100%;
}

.basic-info-title h4 {
  font-size: 1rem;
  line-height: 1.302rem;
  letter-spacing: 0.1rem;
  margin-bottom: 6px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  color: #74757c;
}

.basic-info-title p {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0.75rem;
  font-weight: 400;
  text-transform: none;
  font-size: 20px;
  color: #1f2126;
}

.status_exclusive {
  color: #ffffff;
  background-color: $BLUE;
}

.status_funded {
  color: #3a0ca3;
  background-color: #ebe7f6 !important;
}

.status_accepting {
  color: $GREEN;
  background-color: $ACCPETING_BG;
}
.document-section-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  margin: 0 60px;
  // margin-bottom: 72px;

  .documents-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    .document-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px;
      gap: 8px;
      width: 200px;
      height: 200px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      .document-icon {
        width: 2rem;
        height: 3rem;
        background-image: url("../../assets/download-document.svg");
        @include CONTAIN_NO_REPEAT;
        border: none;
        background-position-y: center;
        background-color: transparent; 
      }
      .doc-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 8px;

        .document-date {
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: #74757c;
        }
        .document-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #1e222e;
        }
      }
    }

    .document-button.no-documents {
      border: 2px dashed #000000;
      background-color: #f4f5f7;
    }
  }
}

.doc-svg {
  @include FLEX_ALIGN_CENTER;
  @include SIZE_18_REM;
  color: $OFF_BLACK;
  padding: 1rem 1.5rem;
  width: calc(50% - 8px);
  height: 4.5rem;
  background-color: #ffffff;
  border-radius: 0.313rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;

  span {
    color: $LIGHT_GRAY;
    line-height: 1.125rem;
    @include WEIGHT_400;
    @include SIZE_14_REM;
    text-align: left;
  }
}

.full_doc {
  width: 100%;
}

.doc-vertical {
  @include FLEX_COLUMN;
}

.doc-icon {
  width: 1.5rem;
  height: 2rem;
  margin-right: 1.063rem;
  background-image: url('../../assets/document.svg');
  @include CONTAIN_NO_REPEAT;
  border: none;
  background-color: transparent;
}
// .document-file{
// }

.cta-container {
  @include FLEX_CENTER;
  flex-direction: column;
  width: fit-content;
  border-radius: 24px;
  padding: 36px 72px 36px 72px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.12);
  margin: auto;
  margin-top: 72px;
  z-index: 1;
  position: relative;
  background-color: #ffffff;
  // transform: translateY(-50%);

  .cta-emphasis-text {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0.5rem;
  }
  .cta-sub-text {
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4d;
    margin-bottom: 36px;
  }
}

.cta-card-btn {
  color: #ffffff;
  background-color: $BLUE;
  border-radius: 2rem;
  padding: 0.75rem 2.25rem;
  border: 2px solid #294fcc;
  transition: 0.2s ease-in-out;

  &:hover {
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #294fcc;
  }
}

.remove_int {
  background-color: $WHITE;
  border: 2px solid #46494d;
  color: #1a1a1a;
  &:hover {
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fcfcfc;
  }
}

.empty-holder-div {
  height: 1000px;
  width: 100vw;
}
.footer-single-cc {
  // transform: translateY(-111px);
}
.footer {
  position: relative;
  background-color: #f6f8ff;
  height: 100%;
  margin-top: -111px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  z-index: 0;
  padding: 220px 160px 60px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-content {
    width: 100%;
    max-width: 1120px;
    .footer-upper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .footer-arbilex {
        width: 40%;

        .footer-logo {
          margin-bottom: 36px;

          .logoMark {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            height: 50px;
          }
          .logo-text {
            font-weight: 500;
            font-size: 36px;
            color: black;
            text-align: left;
            vertical-align: middle;
            line-height: 50px;
            display: inline-block;
            padding-left: 1rem;
          }
        }
        .footer-socials {
          margin: 2rem 0px;

          .footer-socials-list {
            display: flex;
            list-style: none;
            align-items: center;
            margin: auto;
            padding: 0;
            gap: 20px;

            .footer-socials-list-item {
              a:hover g {
                fill: #2f5bea;
                transition: 0.3s;
              }

              svg {
                height: 24px;
                width: 24px;
              }
            }
          }
        }
      }

      .footer-text-section {
        width: 20%;

        .footer-text-title {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          text-transform: uppercase;
          color: #222222;
          margin-bottom: 24px;
        }
      }
    }
    .footer-text-elements {
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.04em;
      white-space: pre-line;

      a {
        color: inherit;
        text-decoration: none;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;
      }
    }

    .footer-lower {
      border-top: 1.5px solid #000000;
      padding-top: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .footer-lower-copyright {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #000000;

        span {
          color: $BLUE;
        }
      }

      .footer-lower-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        align-items: center;
        gap: 12px;

        .footer-separator {
          border: 1px solid #d9d9d9;
        }

        .footer-modal {
          cursor: pointer;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.modal-mega-container.modal-90w {
  max-width: 90vw;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .modal-content {
    width: fit-content;
    max-height: 100vh;
    overflow: auto;
  }
}

.CoS-modal {
  padding: 16px;
}

.policy-modal {
  font-size: 14px;
  font-weight: 400;
  padding: 16px;

  div {
    font-size: inherit;
    font-weight: inherit;
    margin-bottom: 1rem;
  }
  ul,
  li {
    font-size: inherit;
    font-weight: inherit;
  }
}

.modal-title-element {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  font-size: 24px;
  margin-bottom: 24px;
}

.modal-intro-paragraph {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #1f2126;
  margin-bottom: 2rem;
}

.modal-data-footer {
  margin-top: 0.75rem;
  color: #717171;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
}

.data-table {
  border-collapse: initial;
  border-spacing: 4px;
  font-weight: 400;
  background-image: url(../../assets/WaterMark.png);

  .data-table-head {
    border: none;

    .table-logo-container {
      margin: 0;
    }

    img {
      height: 21px;
    }

    td {
      border: 1px solid #d8e1ff;
      background-color: #f3f6ff;
      font-weight: 400;
      padding: 8px 10px;
      text-align: center;
    }

    .table-title {
      text-align: center;
      font-weight: 400;
    }
  }
  .data-table-body {
    background: transparent;

    tr {
      background: transparent;
    }

    td {
      border: 1px solid #e0e0e1;
      width: 20%;
      padding: 8px 10px;
      text-align: center;
      line-height: 26px;
      font-weight: 500;
      font-size: 20px;
    }
  }
}
.mobile-spaced-paragraph {
  white-space: pre-line;
}

.rating-updated-tag-container {
  width: fit-content;
}

.rating-updated-tag{
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  color: #2F5BEA;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration-line: underline;
  width: fit-content;
}

.top-card-container .rating-updated-tag{
  padding-left: 0px;
}

.rating-updated-info-icon {
  max-width: 18px;
  max-height: 18px;
}

#rating-updated-tooltip {
  color: #1f2126;
  background-color: #ffffff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  width: fit-content;
  max-width: 600px;
  padding: 20px;
  font-size: 16px;

  .rating-updated-tooltip-popup-arrow {
    background-color: #2F5BEA;
  }
}

.rating-updated-tag-container .rating-updated-info-title {
  font-size: 16px;
  letter-spacing: .5px;
  margin-bottom: 8px;
}

.factor-history-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

.factor-history {
  width: 100%;
}

.factor-history-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;

  .factor-history-table-headers {
    display: flex;
    align-items: center;
    align-self: stretch;
    color: #74757C;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.12px;
    text-transform: uppercase;

    // version 1
    // border-bottom: #eeeeee thin solid;
    // border-top:#eeeeee thin solid;

    // version 2
    background-color: #f3f3f3;
    border-bottom: #D9D9D9 thin solid;
    border-top:#D9D9D9 thin solid;


    .factor-history-header-table-column {
      display: flex;
      padding: 14px 22px;
      align-items: center;
      gap: 10px; 
    }

    .factor-history-table-date-column {
      flex: 1 0 0;
      border-right:#eeeeee thin solid;
    }
    .factor-history-table-score-column{
      flex: 1 0 0;
      justify-content: center;
    }
    .factor-history-table-event-column{
      flex: 1 0 0;
      border-left:#eeeeee thin solid;
      justify-content: center;
    }
  }

  .factor-history-table-body {
    .factor-history-body-table-row {
      display: flex;
      align-items: center;
      align-self: stretch;
      border-bottom:#eeeeee thin solid;
    }

    .factor-history-body-table-column {
      align-items: center;
      gap: 10px;
      padding: 22px 22px;
      color: #222;
      font-weight: normal;
    }

    .factor-history-table-date-column {
      display: flex;
      flex: 1 0 0;
      height: 82px;
      padding: 22px;
      border-right:#eeeeee thin solid;
    }
    .factor-history-table-score-column{
      display: flex;
      flex: 1 0 0;
      height: 82px;
      justify-content: center;
    }
    .factor-history-table-event-column{
      display: flex;
      height: 82px;
      flex: 1 0 0;
      border-left:#eeeeee thin solid;
      justify-content: center;
    }
  }
}
.rating-updated-date-icon {
  width: 20px;
  height: 20px;
}