@use 'colors';
// @use '../../base/colors';
.center-filter-container {
  display: flex;
  width: 100%;
  padding-top: 24px;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 60px;
  z-index: 300;
  background-color: $WHITE;
  // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.02);
  // box-shadow: 0 10px 14px -14px rgba(0, 0, 0, 0.12);
}
.filter-sort-container {
  align-content: center;
  position: relative;
  // margin: 3em 3.813em 0 3.813em;
  border-bottom: solid 0.1rem black;
  padding-bottom: 8px;

  // margin-bottom: 8px;
  // height: 2.25rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 1120px;
  justify-content: space-between;
}

.fade-tab-container {
  // max-width: 551px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  // overflow: hidden;
  padding-bottom: 0;

  // white-space: nowrap;
  // -webkit-mask-image: linear-gradient(
  //   to right,
  //   rgba(0, 0, 0, 1) 90%,
  //   rgba(0, 0, 0, 0)
  // );
}

.fade-tab-container::-webkit-scrollbar {
  display: none;
}

// .fade-tab-container:before {
//   content: '';
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   left: 0;
//   top: 0;
//   background: linear-gradient(rgb(141, 139, 139) 150px, rgb(206, 99, 99));
//   transform: rotate(90deg);
// }

.listing-menu {
  display: flex;
  list-style: none;
  // width: 42.67%;
  // width: 37.313em;
  margin: 0;
  padding: 0;
  height: 2.25em;
  align-items: center;
  justify-content: space-between;
}

.sort-tab {
  position: relative;
  height: 1.813em !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  border-radius: 0px !important;
  color: rgba(27, 28, 29, 0.5) !important;
  line-height: 1.302em;
  font-size: 1em;
  min-width: fit-content;
  width: fit-content;
  flex-wrap: nowrap;
  /* border-bottom: 3px transparent solid !important; */
  transition: ease-in-out 0.2s;
  cursor: pointer !important;

  &:not(:first-child) {
    margin-right: 8px;
    margin-left: 8px;
  }
  &:first-child {
    margin-right: 8px;
  }
  // &:last-child {
  //   margin-right: 0;
  // }
}

.sort-tab-hover {
  border-radius: 0.125em;
  position: absolute;
  bottom: 0;
  height: 0.25em;
  width: 100%;
  transition: ease-in-out 0.2s;
}
/* ACTIVE TABS */
.sort-tab:hover span {
  background-color: $BLUE;
}
.sort-tab:focus span {
  background-color: $BLUE;
}

.sort-tab:focus {
  color: #1b1c1d !important;
}

.is_active {
  color: #1b1c1d !important;
  span {
    background-color: $BLUE;
  }
}
/* END */
.menu-divider {
  margin: 0 24px;
  border-right: 1px solid rgba(176, 176, 176, 1);
  height: 18px;
}

.dropdown-container {
  display: flex;
  flex-grow: 1;
  height: 2.25em;
  justify-content: right;

  .nav-item.dropdown {
    display: flex;
    height: 100%;
    margin-right: 2%;

    a {
      display: flex;
      align-items: center;
      color: $DARK_GRAY;
    }
  }

  .ref-holder-container {
    display: flex;
    height: 100%;
    position: relative;

    .total-filter-count {
      display: flex;
      color: $WHITE;
      border-radius: 20px;
      top: -5px;
      background-color: $BLUE;
      width: 16px;
      height: 16px;
      font-size: 12px;
      line-height: 24px;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: -4px;
    }
  }
}

#sort-by-dropdown {
  height: 100%;
  font-size: 1em;
  padding: 0;
  align-self: center;
}

#filter-venue-dropdown {
  border-radius: 0.5em;
  border: 0.063em solid #d4d4d4;
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 450 !important;
  line-height: 1.25em !important;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  width: fit-content;
  padding: 0.5em 0.5em 0.5em 1em;
  justify-content: space-between;
}

.filter-button {
  display: flex;
  font-size: 18px;
  background-color: blue;
  border: 0.063rem solid #2c2e30;
  border-radius: 0.5rem;
  color: #2c2e30;
  width: 7.438rem;
  padding: 0.375rem 1rem;
  background-color: transparent;
  line-height: 1.5em;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.filter-svg {
  background-image: url('../../assets/filter.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  margin-left: 0.625em;
}

/* .sort-tab:hover {
  border-bottom: 3px solid #2f5bea !important;
  border-radius: 0.125em !important;
} */

.venue-filter{
  .css-1dimb5e-singleValue {
    font-weight: 400 !important;
  }

  .css-d7l1ni-option {
    font-weight: 400 !important;
  }
  .css-10wo9uf-option {
    font-weight: 400 !important;
  }

  .css-1jqq78o-placeholder{
    font-weight: 400 !important;
  }
  

}
