.auth-background {
  width: 100vw;
  height: calc(100vh - 60px);
  background-image: linear-gradient(rgba(1, 8, 31, 0.3), rgba(1, 8, 31, 0.3)),
    url('../../assets/ArbiLex-V2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-container {
    max-width: 540px;
    padding: 32px 60px;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 8px 4px rgba(0, 0, 0, 0.1);

    .auth-header {
      display: inline-block;
      font-size: 32px;
      font-weight: 700;
      font-family: 'DM Sans', Helvetica, Arial, serif;
      margin-bottom: 32px;
      text-align: center;
      width: 100%;
    }

    .input-container:first-of-type {
      margin: 0 0 24px;
    }

    .auth-input {
      border-radius: 5px;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      padding: 8px 12px;
      border: 1px solid #cacaca;
      background-color: #fefefe;
      box-shadow: inset 0 1px 2px rgba(30, 39, 56, 0.1);
      font-family: inherit;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      color: #1e2738;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    }

    .formik-error {
      color: red;
      margin-top: 8px;
    }

    .helper-text {
      color: #74757c;
      font-size: 16px;

      .blue-text {
        text-decoration: none;
        color: #2f5bea;
        cursor: pointer;
      }

      .underline {
        text-decoration: underline;
        cursor: pointer;
      }
      .login-forgot-link {
        color: #74757c;
      }
    }

    .submit-button {
      width: 100%;
      border: 0;
      border-radius: 0.5rem;
      font-size: 1rem;
      font-weight: 700;
      padding: 1rem 4rem;
      border-color: #fefefe;
      background-color: #2f5bea;
      color: #fefefe;
      outline: none;
      text-align: center;
      margin-top: 36px;
    }

    .submit-button:hover {
      background-color: #1645db;
    }

    .submit-button:active {
      background-color: #1645db;
      border-color: #adadad;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
    }

    hr {
      margin-top: 40px;
      margin-bottom: 24px;
    }

    .success-reset-btn {
      text-decoration: none;
    }
  }
}

.mobile-form-mailto {
  font-weight: 400;
}