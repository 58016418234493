.filter-dropdown {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 30;
  background-color: #ffffff;
  right: 0;
  position: relative;

  #dropdown-button-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.625rem;
    z-index: 100;
    position: sticky;
    bottom: 0;
    background-color: rgba(249, 249, 249, 1);
    max-width: 624px;
    width: 100%;
    padding: 0 24px;
    border-top: 1px solid #dee2e6;
    // max-width:
    .filter-submit-button {
      border: 0;
      border-radius: 0.5rem;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.5rem 1rem;
      outline: none;
      text-align: center;
      transition: all ease-in-out 0.2s;
    }

    .apply-button {
      color: #fefefe;
      background-color: #2f5bea;
      &:hover {
        background-color: #1645db;
      }
    }
    .clear-button {
      background-color: transparent;
      color: $LIGHT_GRAY;
      width: fit-content;
      padding: 0;
    }
  }

  .dropdown-menu.show {
    display: flex;
    height: 300px;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 600;
  }
}

.filter-item {
  background-color: red !important;
  width: 39em;
}

.filter-close-svg {
  border: none;
  background-color: transparent !important;
  position: absolute;
  left: 0.75em;
  background-image: url('../../assets/close-filter.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 1.5em;
  width: 1.5em;
  z-index: 200;
}

#filter-container {
  max-width: 39em;
  display: flex;
  flex-direction: column;
  padding: 21px 1.5em 0.75rem 1.5rem;
  position: relative;

  .case-type-section {
    max-width: 576px;
    .filter-title {
      // height: 1.628rem;
      font-size: 20px;
      line-height: 22px;
      color: #4a4a4a;
    }

    .case-type-container {
      position: relative;
      display: flex;
      justify-content: space-between;

      .nav-item.dropdown {
        display: flex;
        align-items: center;

        a {
          display: flex;

          align-items: center;
        }
      }
    }

    .case-type-container label {
      display: flex;
      color: $DARK_GRAY;
      line-height: 1.5em !important;
      font-weight: 400;
    }
  }

  .case-type-container label input[type='checkbox'] {
    margin-right: 0.75em;
    display: flex;
    position: relative;
    height: 1.5em !important;
    width: 1.5em !important;
    border-radius: 0.25em;
    appearance: none;
    border: solid 1px #d4d4d4;
    color: #4a4a4a;
    font-weight: 400;
  }

  .case-type-container label input[type='checkbox']::before {
    // font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    left: 0.25em;
    font-weight: 800;
    color: #4a4a4a;
    content: '✓';
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  .case-type-container label input[type='checkbox']:checked::before {
    transform: scale(1);
  }
  .case-type-section > div:first-of-type {
    margin-bottom: 0.5em !important;
  }

  .filter-divider {
    background-color: rgba(238, 238, 238, 1);
    height: 1px;
    width: 100%;
    margin: 14px 0;
  }
}

.dropdown-divider {
  margin-top: 0 !important;
  margin-bottom: 1.313em !important;
}

.filter-range-container {
  display: flex;
  flex-direction: column;
  line-height: 1.107em;
  width: 17em;
  &:focus-within {
    .currency-input {
      border: 1px solid $BLUE;
      input {
        outline: none;
      }
    }
  }
  .currency-input {
    color: #4a4a4a;
    font-weight: 400;
    padding-left: 16px;
    width: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 0.5em;
  }
  .currency-input input {
    color: #4a4a4a;
    font-weight: 400;

    border: 0;
    &:focus {
      border: 1px transparent solid;
    }
  }
}

.filter-range-container div {
  border: 1px solid #d4d4d4;
  border-radius: 0.5em;
  height: $BIG_36;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: $DARK_GRAY;
  letter-spacing: 0px;
}
.min-max-container {
  margin-left: 3px;
  border: 1px solid #d4d4d4;
  border-radius: 0.5em;
  height: $BIG_36;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: $DARK_GRAY;
  letter-spacing: 0px;
}

.filter-container-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  height: 3em !important;
  padding: 0;
  color: $DARK_GRAY;
}

.right-checkbox-spacing {
  position: absolute;
  left: 19em;
}

.long-section {
  width: 100% !important;
}

.filter-range-separator {
  width: 1em;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.separator-hyphen-container {
  display: flex;
  width: 0.5em !important;
  height: $BIG_36;
  align-items: center;
}

.separator-hyphen-container span {
  width: 100%;
  height: 0.063em;
  background-color: blue;
  border-top: 0.063em solid $DARK_GRAY;
}

#filter-slider-container {
  flex-direction: column;
}

.filter-box-input {
  display: flex;
}

.date-overlay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 17em;
  &:focus-within {
    border: 1px solid $BLUE;
    input {
      outline: none;
    }
  }
  p {
    margin: 0 0 0 1rem;
    color: $DARK_GRAY;
    font-weight: 400;
  }
}

#filter-date {
  font-weight: 400;
  color: $DARK_GRAY;
  outline: none;
  border: none;
  width: 100%;
  padding-left: 16px;
  padding-right: 8px;
}

.modal-mega-container {
  .modal-content {
    width: 624px;
    max-height: 100vh;
    // padding: 0 1.5em 0 1.5rem;
    padding: 0;
    // position: relative;

    .modal-close-button {
      display: flex;
      cursor: pointer;
      float: left;
      font-size: 1rem;
      left: 1rem;
      top: 1.2rem;
      z-index: 200;
      justify-self: flex-start;
    }
    .modal-header {
      display: flex;
      height: 54px;
      color: #4a4a4a;
      font-size: 18px;
      justify-content: center;
      max-width: 624px;
      width: 100%;
      position: sticky;
      top: 0;
      background-color: #f9f9f9;
      z-index: 100;
    }
    .modal-body {
      // position: relative;
      // display: flex;
      // margin-top: 12px;
    }
  }
}

// DO NOT NEST
.filter-section-subtitle {
  margin-bottom: 0;
  font-size: 14px;
}
// .top-checkbox {
//   // margin-bottom: 0.5em;
// }
// .last-filter-section {
//   margin-bottom: 2em;
// }

// .case-type-container {
//   position: relative;
//   display: flex;
//   justify-content: space-between;

//   .nav-item.dropdown {
//     display: flex;
//     align-items: center;

//     a {
//       display: flex;

//       align-items: center;
//     }
//   }
// }

// .case-type-container label {
//   display: flex;
//   color: $DARK_GRAY;
//   line-height: 1.5em !important;
//   font-weight: 400;
// }
// .filter-title {
//   height: 1.875rem;
//   font-size: 20px;
//   color: #4a4a4a;
// }
// .filter-divider {
//   background-color: rgba(238, 238, 238, 1);
//   height: 1px;
//   width: 100%;
//   margin: 14px 0;
// }

// .case-type-section > div:first-of-type {
//   margin-bottom: 0.5em !important;
// }
/* e61b1b */

// .case-type-container label input[type='checkbox'] {
//   margin-right: 0.75em;
//   display: flex;
//   position: relative;
//   height: 1.5em !important;
//   width: 1.5em !important;
//   border-radius: 0.25em;
//   appearance: none;
//   border: solid 1px #d4d4d4;
//   color: #4a4a4a;
//   font-weight: 400;
// }

// .case-type-container label input[type='checkbox']::before {
//   // font-family: Arial, Helvetica, sans-serif;
//   position: absolute;
//   left: 0.25em;
//   font-weight: 800;
//   color: #4a4a4a;
//   content: '✓';
//   width: 100%;
//   height: 100%;
//   transform: scale(0);
//   transition: 120ms transform ease-in-out;
// }

// .case-type-container label input[type='checkbox']:checked::before {
//   transform: scale(1);
// }
