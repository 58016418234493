.factor-progress-container {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  // max-width: 340px;

  .factor-progress-container-styles {
    height: 0.5rem;
    width: 100%;
    max-width: 340px;

    background-color: $D9;
    border-radius: 0.5rem;
    bottom: 0;

    .factor-progress-styles {
      border-radius: 0.5rem;
      // background-color: $GREEN;
      height: 100%;
    }
  }
}

.isA {
  background-color: $COMP_ARBI_BLU_A;
}
.isB {
  background-color: $COMP_ARBI_BLU_B;
}
.isC {
  background-color: $COMP_ARBI_BLU_C;
}
.isD {
  background-color: $COMP_ARBI_BLU_D;
}
