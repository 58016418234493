@use 'colors';
// @use '../../base/colors';

.container-styles {
  height: 20px;
  width: 100%;
  background-color: rgba(44, 159, 44, 0.1);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  bottom: 0;
}

.filler-styles {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: $GREEN;
  height: 100%;
}
