.dash-stats-container {
  width: 100%;
  max-width: 360px;

  .dash-mod-title {
  }
  .dash-mod-subtitle {
    font-size: 12px;
    line-height: 16px;
    color: #999999;
    font-weight: 400;
    margin-top: 24px;
  }
  .dash-stats-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 24px;
    margin-top: 24px;

    .stat-list-item {
      display: flex;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #585757;
      align-items: center;

      .dash-stats-icons {
        // max-height: 20px;
        // max-width: 22px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .dash-cases-svg {
        width: 24px;
        height: 17px;
        background-image: url('../../assets/dash_stats/stat-cases.svg');
      }
      .dash-parties-svg {
        width: 24px;
        height: 20px;
        background-image: url('../../assets/dash_stats/stat-parties.svg');
      }
      .dash-firms-svg {
        width: 24px;
        height: 24px;
        background-image: url('../../assets/dash_stats/stat-firms.svg');
      }
      .dash-clock-svg {
        width: 24px;
        height: 16.8px;
        background-image: url('../../assets/dash_stats/stat-date.svg');
      }
      .dash-lawyers-svg {
        width: 24px;
        height: 19px;
        background-image: url('../../assets/dash_stats/stat-lawyers.svg');
      }
      .stat-date {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #1c1c1c;
      }
      .dash-stat-right {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .stat-description {
          color: #1c1c1c;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.02em;
        }

        .stat-number {
          font-weight: 500;
          font-size: 18px;
          line-height: 16px;
          letter-spacing: 0.04em;
          color: #1c1c1c;
        }
      }
    }
  }
  .goto-listing {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 36px;
    height: 45px;
    background: #2F5BEA;
    border: 1px solid #1F2126;
    border-radius: 8px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    letter-spacing: 0.08em;
    color: #FFFFFF;

    &:hover {
      background: #264ABE;
      transition: all 0.5s;
    }
  }
}
