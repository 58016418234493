.rating-section-content {
  display: flex;
  column-gap: 36px;

  .rating-left,
  .rating-right {
    display: flex;
    flex-direction: column;
  }

  .rating-left {
    // width: 29.5rem;
    width: 42.7%;
    .rating-flex-container {
      display: flex;
      flex-direction: column;
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 5rem;

      .sigil-holder {
        justify-content: center;
        // width: 450px;
        display: flex;

        .my-sigil {
          margin-top: 0.813rem;
          filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 0.1));
          .funds-allocated-meter {
            .background {
              fill: $WHITE;
            }
            .isA {
              fill: $COMP_ARBI_BLU_A;
            }
            .isB {
              fill: $COMP_ARBI_BLU_B;
            }
            .isC {
              fill: $COMP_ARBI_BLU_C;
            }
            .isD {
              fill: $COMP_ARBI_BLU_D;
            }
          }

          .circle {
            filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 0.1));
          }
        }

        .composite-score-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          // width: 10.188rem;
          margin: 3.911rem 0 0 1.625rem;

          section {
            position: relative;
            width: fit-content;
            color: $COMP_FONT;
            display: flex;
            height: 40px;
            font-size: 1.25rem;
            margin-bottom: 1px;
            margin-top: 1px;
            // border-bottom: 1px solid #65676f;
            // border-right: 1px solid #65676f;
            .legend-number,
            .legend-number-0 {
              position: absolute;
              left: -35px;
              font-size: 14px;
              width: 30px;
              text-align: center;
              font-weight: 400;
            }
            .legend-number {
              top: -10px;
            }
            .legend-number-0 {
              bottom: -10px;
            }

            .comp-range {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 100%;
              padding-left: 1rem;
            }
            .comp-grade-container {
              // position: absolute;
              display: flex;
              width: 3.125rem;

              p {
                margin-bottom: 0;
              }

              .comp-a {
                background-color: $COMP_ARBI_BLU_A;
                // width: 12px;
              }
              .comp-b {
                background-color: $COMP_ARBI_BLU_B;
              }
              .comp-c {
                background-color: $COMP_ARBI_BLU_C;
              }
              .comp-d {
                background-color: $COMP_ARBI_BLU_D;
              }
              div {
                width: 8px;
              }
              p {
                color: $COMP_FONT;
                display: flex;
                align-items: center;
                font-size: 1.25rem;
                line-height: 1.628rem;
                width: 38px;
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .add-factors-div-container {
      display: flex;
      flex-direction: column;
      .rating-calc-button {
        margin-top: 1.5rem;
        background-color: transparent;
        border: 1px solid $D9;
        padding: 12px 24px;
        border-radius: 1.656rem;
        justify-self: center;
        align-self: center;
      }
    }
  }
  .rating-right {
    width: 57.3%;

    .rating-holder {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 3rem;
    }

    .factor-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      width: 100%;

      .factor-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;

        .factor-label-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0px;
          width: 100%;
        }

        .factor-content-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0px;
          width: 100%;
        }
      }

      .factor-explanation-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
      }

      .factor-label {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #74757c;
      }
      .factor-content.content-explanation {
        white-space: pre-line;
      }
      .factor-content {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #1f2126;

        .modal-intro-paragraph {
          font-weight: inherit;

          .data-table-modal-link {
            font-weight: 500;
            color: #2f5bea;
          }
        }
      }
    }
  }

  // .rating-right {
  //   width: 57.3%;

  //   .rating-holder {
  //     display: flex;
  //     padding-left: 2.25rem;
  //     margin-bottom: 24px;

  //     .rating-card {
  //       display: flex;
  //       align-items: stretch;
  //       flex-direction: column;
  //       justify-content: space-between;
  //       width: 100%;
  //       height: fit-content;
  //       background-color: $FACTOR_GRAY;
  //       box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  //       border-radius: 0.625rem;
  //       padding: 1rem;
  //       cursor: pointer;

  //       .right-open-holder {
  //         margin-top: 1rem;
  //         padding: 1rem;
  //         background-color: $WHITE;
  //         color: #333333;
  //         line-height: 1.8rem;
  //         font-weight: 400;
  //         border-radius: 5px;

  //         .right-open-title {
  //           font-weight: 500;
  //           margin-bottom: 4px;
  //         }
  //       }

  //       .right-card-row {
  //         display: flex;
  //         justify-content: space-between;
  //       }
  //       .right-factor-title {
  //         display: flex;
  //         // justify-content: censter;
  //         align-items: center;
  //         color: $DARK2;
  //         margin: 0;
  //         font-size: 20px;

  //         .free-check {
  //           background-image: url('../../assets/lock.svg');
  //           background-repeat: no-repeat;
  //           background-size: contain;
  //           background-position: center;
  //           height: 13px !important;
  //           min-width: 13px !important;
  //           margin-left: 8px;
  //         }
  //         .rating-card-arrow {
  //           margin-right: 1.375rem;
  //           border: none;
  //           width: 14px;
  //           height: 14px;
  //           background-image: url('../../assets/doc-arrow.svg');
  //           background-repeat: no-repeat;
  //           background-size: contain;
  //           background-color: transparent;
  //         }

  //         .open_chance {
  //           height: 14px;
  //           width: 14px;
  //           background-image: url('../../assets/arrow-down.svg');
  //         }
  //       }

  //       .right-factor-score-holder {
  //         display: flex;
  //         align-items: center;
  //         justify-content: space-between;
  //         font-weight: 400;
  //         font-size: 1.25rem;
  //         letter-spacing: -2%;
  //         line-height: 1.628rem;
  //         width: 9.75rem;

  //         .right-factor-score {
  //           margin-bottom: 0;
  //           // margin: 0 0 0 0.625rem;
  //           font-size: 1.5rem;
  //           font-weight: 700;
  //           line-height: 1.953rem;
  //         }

  //         .right-factor-score.free-member {
  //           filter: blur(3px);
  //         }
  //       }
  //     }
  //     .big_rating_card {
  //       height: 500px;
  //     }
  //   }
  // }
}
.free-chance {
  font-weight: 400;
  filter: blur(3px);
  user-select: none;
}
svg .inside {
  text-anchor: middle;
  dominant-baseline: middle;
  vertical-align: middle;
  text-align: center;
}

svg .g-element {
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: center;
}

.document-section-holder {
  margin-bottom: 70px;

  label, textarea {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }

  textarea {
      padding: 10px;
      max-width: 100%;
      line-height: 1.5;
      height: 210px;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #000000;
      border-radius: 8px;
  }

  label {
      display: block;
      margin-bottom: 12px;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.08em;
      color: #000000;

  }

  .interest-button {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 36px;
    height: 45px;
    background: #2F5BEA;
    border: 1px solid #1F2126;
    border-radius: 8px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    letter-spacing: 0.08em;
    color: #FFFFFF;

    &:hover {
      background: #264ABE;
      transition: all 0.5s;
    }
  }


  .interest-left {
    display: inline-block !important;
    float: left;
    width: 35%;

    .interest-text {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      color: #1F2126;
      margin-top: 4px;
    }

    .interest-subsection-title {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #74757C;
    }

  }

  .interest-right {
    display: inline-block !important;
    margin-left: 5%;
    width: 59%;

    textarea{
      outline: none;
      font-size: 18px;
      font-weight: 400;
    }


    .email-box {
      margin-bottom: 20px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1.5px solid #000000;
      width: 320px;
      outline: none;

      // input {
        font-family: DM Sans;
        font-size: 20px ;
        font-weight: 400 !important;
        line-height: 26px ;
        letter-spacing: 0.08em !important;
        text-align: left;

      // }
  
    }
  }

}


