// @media (min-width: 768px) and (max-width: 904px){
// .support-footer {
//     padding: 100px 32px 60px 32px;
//   }
// }

// @media (min-width: 320px) and (max-width: 599px) {
//   .support-container {
//     .support-form-container {
//       flex-direction: column;
//       align-items: center;
//       .support-left {
//         width: 100%;
//         margin-bottom: 60px;
//       }
//       .support-right {
//         width: 100%;
//       }
//     }
//   }
// }

@media (min-width: 320px) and (max-width: 799px) {
  .support-container {
    .support-form-container {
      flex-direction: column;
      align-items: center;
      padding: 16px;
      padding-bottom: 0;
      position: relative;

      @include fluid-type(margin-top, 320px, 799px, 42px, 86px);
      @include fluid-type(margin-bottom, 320px, 799px, 60px, 86px);

      .support-left {
        width: 100%;
        // margin-bottom: 60px;
        .support-header {
          @include fluid-type(font-size, 320px, 799px, 16px, 32px);

          line-height: normal;
          // @include fluid-type(line-height, 320px, 799px, 16px, 18px);
        }
        .support-sub-text {
          @include fluid-type(font-size, 320px, 799px, 12px, 20px);
          @include fluid-type(margin-top, 320px, 799px, 12px, 20px);
          @include fluid-type(margin-bottom, 320px, 799px, 36px, 48px);
          @include fluid-type(line-height, 320px, 799px, 15.6px, 26px);
          // line-height: normal;
        }
      }
      .support-right {
        width: 100%;
        min-width: 296px;
        height: fit-content;
        @include fluid-type(padding, 320px, 799px, 8px, 10px);
        @include fluid-type(margin-right, 320px, 799px, -8px, 0px);
        @include fluid-type(margin-left, 320px, 799px, -8px, 0px);

        // position: absolute;

        .support-form {
          padding: 16px;
          height: fit-content;
          width: 100%;
       

          .support-input-field {
            @include fluid-type(font-size, 320px, 799px, 12px, 16px);
            @include fluid-type(margin-bottom, 320px, 799px, 12px, 36px);

            .formik-error {
              @include fluid-type(font-size, 320px, 799px, 10px, 16px);
            }
            .support-products {
              background-size: 12px 6px;
            }
          }
          .support-last-input {
            @include fluid-type(font-size, 320px, 799px, 12px, 16px);
            // margin-bottom: 0;

            textarea {
              @include fluid-type(height, 320px, 799px, 120px, 182px);
              // height: 30px;
            }
            .formik-error {
              @include fluid-type(font-size, 320px, 799px, 10px, 16px);
            }
          }
          .support-submit-button {
            @include fluid-type(height, 320px, 799px, 40px, 58px);
            @include fluid-type(margin-top, 320px, 799px, 12px, 36px);
            @include fluid-type(font-size, 320px, 799px, 12px, 20px);
            padding: initial;
          }
        }
      }

      .support-faq {
        margin-top: 36px;
        margin-bottom: 36px;
        width: 100%;

        .support-faq-header {
          font-weight: 700;
          @include fluid-type(font-size, 320px, 799px, 16px, 32px);
          @include fluid-type(margin-bottom, 320px, 799px, 16px, 32px);

          line-height: normal;
        }

        .support-faq-body {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          .support-faq-qa {
            .support-faq-q {
              font-weight: 700;
              @include fluid-type(font-size, 320px, 799px, 12px, 16px);
            }
            .support-faq-a {
              font-weight: 400;
              @include fluid-type(font-size, 320px, 799px, 12px, 16px);
            }
          }
        }
      }

      .support-email {
        width: 100%;

        .support-email-header {
          font-weight: 700;
          @include fluid-type(font-size, 320px, 799px, 16px, 32px);
        }

        .support-email-us {
          font-weight: 400;
          @include fluid-type(font-size, 320px, 799px, 12px, 16px);
        }
      }
    }
  }
}

@media (min-width: 799px) and (max-width: 1000px) {
  .support-container {
    .support-form-container {
      .support-left {
      }
      .support-right {
        .support-form {
          padding: 35px 20px;
        }
      }
    }
  }
}
@media (min-width: 600px) and (max-width: 1239px) {
  .support-container {
    padding: 0 32px;
  }
}
