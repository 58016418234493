// $donut-tab-width: 266.820px;

.dash-breakdown-container {
  max-width: 740px;
  width: 100%;

  .dash-mod-title {
  }
  .case-donut-title-holder {
    display: flex;
    position: relative;

    .donut-tab-container {
      display: flex;
      position: absolute;
      right: 56px;
      column-gap: 24px;
      height: 100%;
      height: 54px;

      .donut-tab-holder {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .donut-tab-title {
          border: none;
          height: 28px;
          background-color: transparent;
          color: #969696;
          font-weight: 700;
          font-size: 14px;
          line-height: 28px;
        }
        .donut_tab_title {
          color: #2f5bea;
        }
        .tab-bottom-highlight {
          position: absolute;
          bottom: 0;
          border-bottom: 1px solid transparent;
          max-width: 44.76px;
          width: 100%;
          transition: 0.2s ease-in-out;
        }
        .donut_tab_ht {
          border-bottom: 1px solid #2f5bea;
        }
      }
    }
  }

  .dash-brk-divider {
    margin: 25px 0;
    border-top: 1px solid #e8e8e8;
  }

  .dash-donut-holder {
    height: 300px;
    // padding: 0 92px 0 72px;
    display: flex;
    margin: 0 0 0 72px;

    .donut-stats-container {
      padding: 60px 0;
      max-width: 183px;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      .dash-donut-rows {
        display: flex;
        align-items: center;
        position: relative;
        .donut_unk_claim {
          background-color: #E3F0BD;
        }
        .donut_10_claim {
          background-color: #c5da95;
        }
        .donut_50_claim {
          background-color: #a3c744;
        }
        .donut_100_claim {
          background-color: #66962d;
        }
        .donut_500_claim {
          background-color: #448310;
        }
        .donut_1b_claim {
          background-color: #245e03;
        }
        .donut_1bplus_claim {
          background-color: #1f3509;
        }
        // .donut_10_claim {
        //   background-color: #edf8fb;
        // }
        // .donut_50_claim {
        //   background-color: #ccece6;
        // }
        // .donut_100_claim {
        //   background-color: #99d8c9;
        // }
        // .donut_500_claim {
        //   background-color: #66c2a4;
        // }
        // .donut_1b_claim {
        //   background-color: #41ae76;
        // }
        // .donut_1bplus_claim {
        //   background-color: #238b45;
        // }
        // .donut_unk_claim {
        //   background-color: #005824;
        // }

        .dash-donut-circle {
          min-width: 20px;
          height: 20px;
          border-radius: 40px;
          margin-right: 12px;
        }
        .donut_rating_a {
          background: #2f5bea;
        }
        .donut_rating_b {
          background: #0090fc;
        }
        .donut_rating_c {
          background: #5fbcff;
        }
        .donut_rating_d {
          background: #b1e4ff;
        }
        .dash-donut-letter {
          color: #313131;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
        }
        .dash-donut-number {
          color: #313131;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          margin-left: auto;
          // position: absolute;
          // right: 0;
          align-self: flex-end;
        }
      }
    }
  }

  .dash_donut_holder_amnt {
  }
  .dash_donut_holder_claim {
    // padding: 0 92px;
    // padding: 0 92px 0 72px;
    // margin-rigx/ht: 72px;

    justify-content: space-between;

    .donut-stats-container {
      padding: 0 0;
      max-width: 183px;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      .dash-donut-rows {
        .dash-donut-letter {
          color: #313131;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
  .dash_donut_holder_case {
    // padding: 0px 25px 0px 50px;
    // padding: 0 92px 0 72px;

    .donut-stats-container {
      // max-width: 240px;

      .dash-donut-rows {
        .dash-donut-letter {
          font-size: 16px;
        }
        .dash-donut-number {
          // displa
        }
        .donut_case_ae {
          background-color: #fb49b0;
        }
        .donut_case_ita {
          background-color: #4053d3;
        }

        .donut_case_p {
          background-color: #00beff;
        }
      }
    }
  }
}
