.support-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .support-form-container {
    justify-content: space-between;
    margin-top: 86px;
    margin-bottom: 121px;
    height: 100%;
    max-width: 1120px;
    display: flex;

    .support-left {
      // width: 448px;
      width: 40%;

      .support-header {
        font-weight: 700;
        font-size: 40px;
        line-height: 52.08px;
      }
      .support-sub-text {
        margin: 20px 0 48px 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
      }
      .support-faq {
        .support-faq-header {
          font-size: 32px;
          font-weight: 700;
          line-height: 41.7px;
        }
        .support-faq-body {
          margin: 20px 0 48px 0;
          gap: 20px;
          display: flex;
          flex-direction: column;
          .support-faq-qa {
            display: flex;
            flex-direction: column;

            .support-faq-q {
              font-weight: 700;
              line-height: 20.832px;
              font-size: 16px;
              margin-bottom: 8px;
            }
            .support-faq-a {
              line-height: 20.832px;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
      .support-email {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .support-email-header {
          font-weight: 700;
          font-size: 32px;
          line-height: 41.664px;
        }
        .support-email-us {
          font-weight: 400;
          line-height: 20.832px;
          font-size: 16px;
        }
      }
    }
    .support-right {
      position: relative;
      display: flex;
      height: fit-content;
      // width: 596px;
      width: 53.21%;
      max-width: 596px;
      padding: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .support-tr {
        background-color: #fab908;
        width: 200px;
        height: 100px;
        align-self: flex-start;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .support-bl {
        background-color: #fab908;
        width: 200px;
        height: 100px;
        align-self: flex-end;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
      }
      .support-form {
        display: flex;
        flex-direction: column;
        border-radius: 0px 24px;
        padding: 72px 40px;
        background-color: #ffffff;
        height: fit-content;
        // width: 576px;
        border: 1px black solid;
        z-index: 2;

        .input-container {
          position: relative;
          label {
            font-weight: 400;
          }
          .support-products {
            margin-bottom: 0;
          }
          .auth-input {
            margin-bottom: 0;
            margin-top: 8px;
          }
          .formik-error {
            position: absolute;
            margin-bottom: 0;
            color: red;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .support-input-field {
          margin-bottom: 36px;

          .support-products {
            // .support-down-arrow {
            // display: flex;
            background-position: center;
            background-size: 16px 8px;
            background-repeat: no-repeat;
            background-position: right 16px top 50%, 0 0;
            background-image: url('../../assets/landing/down-support.svg');
            // background-size: 0.65em auto, 100%;
            // }
          }
          input,
          select {
            width: 100%;
            height: 40px;
            font-weight: 400;
            // margin: 0 0 1.14286rem;
            padding: 0.57143rem;
            border: 1px solid #d4d4d4;
            border-radius: 2px;
            background-color: #fcfcfc;
            box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.04);
            transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
            // box-shadow: inset 0 1px 2px rgba(30, 39, 56, 10%);

            &:focus {
              outline: none;
              border: 1px solid #8a8a8a;
              box-shadow: 0 0 5px #cacaca;
              transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
            }
          }

          select {
            position: relative;
            color: #b2b2b2;
            margin-top: 8px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &::before {
              position: absolute;
              content: '';
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              height: 20px;
              width: 10px;
              background-color: red;
              background-image: url('../../assets/landing/logo-black.svg');
            }
          }
        }
        .support-last-input {
          .auth-input {
            width: 100%;
            margin-top: 8px;
            font-weight: 400;
            padding: 0.57143rem;
            border: 1px solid #d4d4d4;
            border-radius: 2px;
            background-color: #fcfcfc;
            box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.04);
            transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
          }
          &:focus {
            outline: none;
            border: 1px solid #8a8a8a;
            background-color: #fefefe;
            box-shadow: 0 0 5px #cacaca;
            transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
          }
        }

        .support-submit-button {
          margin-top: 36px;
          // width: 496px;
          height: 58px;
          width: 100%;
          border: 0;
          border-radius: 8px;
          font-size: 20px;
          font-weight: 700;
          // padding: 16px 178.5px;
          // padding: 1rem 4rem;
          padding: 16px 0;
          line-height: 26px;
          border: 2px solid #294fcc;
          background-color: #2f5bea;
          color: #fefefe;
          outline: none;
          cursor: pointer;
          text-align: center;
        }
      }
    }
  }
}
.support-footer {
  display: flex;
  flex-direction: column;
  background-color: #f6f8ff;
  height: 387px;
  width: 100%;
  padding: 100px 160px 60px 160px;

  .footer-info-container {
    display: flex;
    justify-content: space-between;

    .support-footer-left {
      .support-footer-arbilex {
        display: flex;
        align-items: center;
        margin-bottom: 33px;

        .support-footer-logo {
          height: 30px;
          width: 32px;
          background-image: url('../../assets/landing/logo-black.svg');
        }

        .support-footer-name {
          line-height: 31.248px;
          font-size: 24px;
          font-weight: 500;
          margin-left: 7px;
          color: #000000;
        }
      }

      .support-footer-address {
        color: #000000;
        letter-spacing: 0.56px;

        p {
          font-weight: 400;
          line-height: 21px;
          font-size: 16px;
          margin-bottom: 0;
        }

        .support-email {
          margin-bottom: 8px;
        }
      }

      .support-footer-socials {
        display: flex;
        align-items: flex-end;
        column-gap: 20px;
        margin-top: 34px;

        .footer-AL {
          width: 12.5px;
          height: 18px;
          background-image: url('../../assets/icons/Angellist-logo.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .footer-twitter {
          width: 20px;
          height: 15.65px;
          background-image: url('../../assets/icons/Twitter-logo.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .footer-LI {
          width: 18px;
          height: 18px;
          background-image: url('../../assets/icons/Linkedin-logo.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
    .support-footer-right {
      display: flex;

      .support-links,
      .resources-links {
        .support-footer-sub {
          color: #222222;
          font-weight: 700;
          font-size: 14px;
          line-height: 18.23px;
          margin-bottom: 24px;
        }
        a {
          text-decoration: none;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          letter-spacing: 4%;
          line-height: 20.83px;
          margin-bottom: 8px;
          transition: ease-in-out 0.3s;
          &:hover {
            color: #2f5bea;
          }
        }
      }
      .resources-links {
        margin-left: 215.5px;
      }
    }
  }

  .support-footer-small-text {
    display: flex;
    justify-content: space-between;
    border-top: 1.5px solid #d9d9d9;
    margin-top: 36px;
    padding-top: 12px;

    .support-small-left {
      p {
        color: #000000;
        line-height: 15.62px;
        font-weight: 400;
        font-size: 12px;

        span {
          color: #2f5bea;
          font-weight: 500;
        }
      }
    }
    .support-small-right {
      display: flex;
      height: fit-content;

      p {
        line-height: 15.62px;
        font-size: 12px;
        font-weight: 400;
        color: #000000;
        margin: 0;
      }
      .terms-divider {
        width: 1px;
        margin: 0 12px;
        background-color: #eeeeee;
      }
    }
  }
}
