.modal-element-body {
  margin: 5% 5vw;
  // height: 100vh;

  .modal-content {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 546px;
    padding: 0;

    .modal-body {
      overflow: auto;

      .modal-req-holder {
        margin: 24px;
        // margin: 0 24px 24px 24px;

        .modal-req-container {
          .modal-req-id {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: rgba(84, 89, 94, 0.6);
          }
          .modal-title-element {
            margin: 8px 0;
            font-family: 'Lora';
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            color: #1b1b1b;
            text-align: left;
          }
        }
        .modal-body-element {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(84, 89, 94, 0.6);
          margin-bottom: 24px;
        }
        .modal-info-holder {
          display: flex;
          justify-content: space-between;

          .modal-el {
            display: flex;
            flex-direction: column;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #4f4f4f;

            .modal-el-date,
            .modal-el-status,
            .modal-el-activity {
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #4f4f4f;
            }
            .modal-el-status {
            }
            .modal-el-activity {
            }
          }
        }
      }
    }
  }
  //   .modal-element-close-button {
  .modal-element-close-holder {
    // display: flex;
    // padding: 10px 10px 0 10px;
    // justify-content: center;
    // align-items: center;
    // left: 512px;
    // top: 10px;
    // background: #e5e5e5;
    // border-radius: 29px;
    position: relative;
    // right: 10px;
    // top: 10px;
    height: fit-content;

    .modal-element-close-button {
      position: absolute;
      z-index: 300;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      background-image: url('../../assets/dash-modal/modal-el-close2.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      // b
    }
  }
}

@media (min-width: 320px) and (max-width: 599px) {
  .modal-dialog.modal-element-body {
    max-width: 100%;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    .modal-content {
      overflow: auto;
      max-width: 100%;

      position: absolute;
      bottom: 0;
      height: 90dvh;
      .modal-body {
        .modal-req-holder {
          margin: 0;
          .modal-req-container {
            background-color: #f5f5f5;
            padding: 32px 16px 16px 16px;
            .modal-req-id {
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              color: rgba(84, 89, 94, 0.6);
            }
            .modal-title-element {
              margin: 16px 0 0 0;
              font-size: 24px;
              line-height: 31px;
              color: #1b1b1b;
              text-align: left;
            }
          }
          .modal-body-element {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: rgba(84, 89, 94, 0.6);
            margin: 16px;
          }
          .modal-info-holder {
            flex-wrap: wrap;
            row-gap: 16px;
            margin: 0 16px;
            .modal-el {
              row-gap: 4px;
              align-items: flex-start;
              flex: 50%;
              .modal-el-title {
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
              }
              .modal-el-date,
              .modal-el-status,
              .modal-el-activity {
              }
              .modal-el-status {
              }
              .modal-el-activity {
              }
            }
          }
        }
      }
    }
    //   .modal-element-close-button {
    .modal-element-close-holder {
      .modal-element-close-button {
        top: 16px;
        right: 16px;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 904px) {
  .modal.show.modal-element-body {
    max-width: 100%;
    margin: auto;
    // height: fit-content;

    // max-width: 546px;
    // height: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
    .modal-content {
      overflow: auto;
      width: 546px;

      position: absolute;
      height: fit-content;
      .modal-body {
        .modal-req-holder {
          margin: 0;
          .modal-req-container {
            background-color: transparent;
            padding: 24px 24px 8px 24px;
            .modal-req-id {
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              color: rgba(84, 89, 94, 0.6);
            }
            .modal-title-element {
              margin: 16px 0 0 0;
              font-size: 24px;
              line-height: 31px;
              color: #1b1b1b;
              text-align: left;
            }
          }
          .modal-body-element {
            margin: 0 24px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: rgba(84, 89, 94, 0.6);
          }
          .modal-info-holder {
            flex-wrap: nowrap;
            row-gap: 16px;
            margin: 24px;
            .modal-el {
              row-gap: 4px;
              align-items: flex-start;
              flex: 50%;
              .modal-el-title {
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
              }
              .modal-el-date,
              .modal-el-status,
              .modal-el-activity {
              }
              .modal-el-status {
              }
              .modal-el-activity {
              }
            }
          }
        }
      }
    }
    //   .modal-element-close-button {
    .modal-element-close-holder {
      .modal-element-close-button {
        top: 16px;
        right: 16px;
      }
    }
  }
}

@media (min-width: 905px) {
  .modal-dialog.modal-element-body {
    max-width: 720px;
    width: fit-content;
    .modal-content {
      width: 720px;
      max-width: 720px;
    }
  }
}
