.main-nav {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  font-size: 14px;
  // padding: 0 3.813em;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: $WHITE;
  // background-color: aquamarine;
  z-index: 400;

  .no-account-nav {
    display: flex;

    .no-account-nav-title {
      display: flex;
      margin-bottom: 0;
      color: #899099;
      line-height: 18px;
      font-size: 14px;
      font-weight: 400;
      align-items: center;
    }

    .to-sign-up {
      background-color: $BLUE;
      padding: 8px 24px;
      color: $WHITE;
      text-decoration: none;
      border-radius: 5px;
      margin-left: 1rem;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
    }
  }
  .user-menu-dropdown {
    // margin-right: 5rem;
    height: 1.875rem;

    ul {
      height: 100%;

      .nav-item {
        height: 100%;
        #basic-nav-dropdown {
          padding: 6px 12px;
          display: flex;
          align-items: center;
          height: 100%;
          color: $GRAPHITE;
          border: 1px solid #e6e6e6;
          border-radius: 15px;
          text-decoration: none;
        }
      }
    }
  }
}
.center-nav-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1120px;
}

.logo-link {
  text-decoration: none;
}

.afx-logo {
  color: $BLUE;
  @include WEIGHT_700;
  line-height: 36.5px;
  font-size: 28px;
  // margin-left: 2.86em;
}

.nav-menu {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  align-items: center;
  height: 60px;

  ul {
    padding: 0;

    .flex-row {
      .nav-tab {
        color: $GRAPHITE;
        border-radius: 0px;
        border-bottom: 3px transparent solid;

        &:hover {
          border-bottom: 3px solid #2f5bea;
          border-radius: 0px;
          color: #2f5bea;
          cursor: pointer;
        }
      }
    }
  }
  .nav-item {
    display: flex;
    text-align: center;

    a {
      // padding: 21px 16px;
      color: $GRAPHITE;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
}

.nav,
.nav li,
.nav li a {
  display: flex;
  height: 100%;
  text-align: center;
}

.user-dropdown-content {
  height: 30px;
}

.nav-tab.active {
  color: $BLUE !important;
  background-color: #f6f8ff !important;
  border-bottom: 3px solid #2f5bea !important;
  border-radius: 0px;
}
