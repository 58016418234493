body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

// :root {
//   --toastify-toast-background: red;
//   .Toastify__progress-bar {
//     background-color: red;
//   }
// }

@import 'base/module';
@import 'components/module';
