//sort by dropdown icon disappear
.sort{
  margin-right: 10px;
.dropdown-toggle::after {
    display: none !important;
  }
  .sort-icon {
    width: 22px;
    height: 22px;
    margin-left: 0.1em;
  }
}
  
  // dropdown icon
.search-div{
  .css-1hb7zxy-IndicatorsContainer  .css-1xc3v61-indicatorContainer:last-of-type {
    display: none !important;
  }
  
  .css-1xc3v61-indicatorContainer{
    padding: 8px;
  }
  
  .css-1wy0on6 .css-1xc3v61-indicatorContainer:last-of-type {
    display: none !important;
  }
  
  
  .css-1hb7zxy-IndicatorsContainer  .css-15lsz6c-indicatorContainer:last-of-type {
    display: none !important;
  }
  
  .css-1wy0on6 .css-15lsz6c-indicatorContainer:last-of-type {
    display: none !important;
  }
  .css-15lsz6c-indicatorContainer{
    padding: 8px;
  }
  
  
  //cancel display of the seperate line before dropdown icon
  .css-1u9des2-indicatorSeparator{
    display: none;
  }
  
  .css-1fdsijx-ValueContainer {
    padding: 0px 6px !important;
    font-size: 18px;
    cursor: text !important;
  }
  
  .css-hlgwow {
    padding: 0px 6px !important;
    font-size: 18px;
    cursor: text !important;
  }
  
  
  //menu list
  .qr46ko {
    margin-bottom: 12px;
  }
  .css-1n6sfyn-MenuList {
    margin-bottom: 12px;
  }
  .css-cpryk8-menu {
    max-width: 60vh ;
    width: max-content;
  }

  .css-1dim5be {
    cursor: text !important;
  }
  
  //dropdown options
  .css-10wo9uf-option {
    font-size: 18px;
    border-left: 4px solid transparent !important;
    padding-left: 16px !important;
  }
  
  /* hoover */
  .css-d7l1ni-option {
    font-size: 18px ;
    background-color: #f5f5f5 !important;
    border-left: 4px solid #808080 !important;
    padding-left: 16px !important;
  }
  /* chosen */
  .css-tr4s17-option {
    font-size: 18px;
    color: currentColor !important;
    background-color: #f6f8ff !important;
    border-left: 4px solid #2f5bea !important;
    padding-left: 16px !important;
  }
  
  
  // search bar
  .css-1fdsijx-ValueContainer {
    cursor: text !important;
  }
  
  .css-13cymwt-control{
    min-height: fit-content !important;
    max-width: 60vh !important;
    width: fit-content !important;
    height: 36px;
    align-self: center;
    position: relative;
    border: none !important;

      display: flex;
      font-weight: 500;
      text-align: center;
      transition: all 200ms;
      vertical-align: baseline;
      white-space: nowrap;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
  }

  .css-t3ipsp-control{
    max-width: 70vh !important;
    width: fit-content !important;
    min-height: fit-content !important;
    /* max-width: 260px;
    width:100% !important; */
    height: fit-content !important;
    align-self: center;
    position: relative;
    border: none !important;
    box-shadow: none !important;
      color: #333333;
      cursor: pointer;
      display: flex;
      font-weight: 500;
      text-align: center;
      transition: all 200ms;
      vertical-align: baseline;
      white-space: nowrap;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
  }
  
  }
  
  
  //search icon
  .search-icon {
    width: 16px  ;
    color: currentColor;
  }
  
  #sort-by-dropdown {
    border : 0.063rem solid #2c2e30;
    border-radius: 0.5rem;
    padding: 0px 10px ;
  }
  
  
  .p {
    background-color: #f6f8ff !important;
    border-left: 3px solid #2f5bea !important;
  }
  
  
  
  
  

