@media (min-width: 320px) and (max-width: 599px) {
  .my-brk-donut {
    @include fluid-type(height, 320px, 599px, 200px, 265px);

    .donut-box {
    }
    .donut-paths {
    }
  }

  .dash-container {
    @include fluid-type(padding-left, 320px, 599px, 16px, 28px);
    @include fluid-type(padding-right, 320px, 599px, 16px, 28px);

    .dash-comp-container {
      @include fluid-type(margin-top, 320px, 599px, 12px, 16px);

      .my-dash-title {
        @include fluid-type(font-size, 320px, 599px, 16px, 18px);
        @include fluid-type(line-height, 320px, 599px, 24px, 28px);
        @include fluid-type(height, 320px, 599px, 24px, 28px);
      }
      .dash-title-divider {
        margin-top: 4px;
        @include fluid-type(margin-bottom, 320px, 599px, 16px, 20px);
      }
      .dash-section-box {
        .dash-section-holder {
          flex-direction: column;
          gap: 0px;

          .dash-stats-container,
          .dash-bookmarks-container,
          .dash-most-container,
          .dash-reqs-container,
          .dash-breakdown-container {
            @include fluid-type(padding, 320px, 599px, 12px, 16px);

            .case-donut-title-holder {
              justify-content: space-between;
            }
            .dash-mod-title {
              @include fluid-type(font-size, 320px, 599px, 14px, 16px);
              line-height: 24px;

              .case-donut-title {
                font-weight: 700;
              }

              .donut-select-drop {
                width: 70px;
                height: 24px;
                background: #ffffff;
                border: 1px solid #4a4a4a;
                border-radius: 8px;
                padding: 4px 8px;

                .dropdown-menu {
                  margin-top: 5px;
                }
                #breakdown-link-container {
                  height: 100%;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 15.62px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  text-decoration: none;
                  color: #4a4a4a;
                  line-height: 12px;
                  padding: 0;
                }
              }
            }
          }

          .dash-top-stat-breakdown {
            display: flex;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out;
            border-top: 1px solid #e8e8e8;
            flex-direction: column;
            height: fit-content;
            max-height: 800px;
            .dash-stats-container {
              border-radius: 0;
              border-bottom: 1px solid #e8e8e8;
            }
            .dash-breakdown-container {
              border-radius: 0 0 8px 8px;
            }
          }
        }

        .stat-breakdown-collapse {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include fluid-type(font-size, 600px, 904px, 16px, 20px);
          font-weight: 700;
          cursor: pointer;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
            0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.04);
          // height: 48px;
          border-radius: 8px 8px 0px 0px;
          @include fluid-type(padding, 600px, 904px, 14px, 24px);
          background-color: $WHITE;
          transition: background-color 0.3s ease-in-out;

          &:hover {
            background-color: rgba(194, 194, 194, 0.089);
          }

          .dashboard-dropdown-icon {
            background-image: url('../../assets/dashboard-dropdown-arrow.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 30px;
            height: 30px;
            transform: rotate(180deg);
            transition: transform .3s ease-in-out;
          }
          .dashboard-dropdown-icon.collapsed {
            transform: rotate(0deg);
          }
        }

        .stat-breakdown-collapse.collapsed {
          border-radius: 8px;
          transition: border-radius .3s ease-in-out;
        }

        .dash-top-stat-breakdown.collapsed {
          max-height: 0;
        }

        .dash-breakdown-container {
          border-radius: 20px;

          .dash-donut-holder {
            @include fluid-type(height, 320px, 599px, 300px, 341px);
            margin: 0;
            flex-direction: column;

            .my-brk-donut {
              margin: auto;
              @include fluid-type(max-width, 320px, 599px, 240px, 480px);
            }

            .donut-stats-container {
              padding: 0;
              max-width: none;
              flex-direction: row;
              flex-wrap: wrap;
              width: auto;
              @include fluid-type(column-gap, 320px, 599px, 8px, 24px);
              row-gap: 4px;
              justify-content: space-around;

              .dash-donut-rows {
                width: fit-content;

                .dash-donut-circle {
                  min-width: 12px;
                  width: 12px;
                  height: 12px;
                  margin-right: 4px;
                }
                .dash-donut-letter {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 21px;
                  margin-right: 16px;
                }
                .dash-donut-number {
                  display: flex;
                  align-items: center;
                  font-weight: 700;
                  font-size: 14px;
                  height: 100%;
                  text-align: center;
                  line-height: 18px;
                  position: relative;
                }
              }
            }

            .dsc_tab2 {
              margin: 0;
              height: 76px;
              row-gap: 0;
              column-gap: 20px;
              align-items: center;
              justify-content: space-around;

              .dash-donut-rows {
                height: 16px;
                position: relative;
                min-width: 120px;
                flex: none;

                .dash-donut-letter {
                  font-size: 12px;
                }
                .dash-donut-number {
                  font-size: 12px;
                  // position: absolute;
                }
              }
            }

            .dsc_tab3 {
              margin: 0 12px;
              .dash-donut-rows {
                flex: 50%;
                height: fit-content;

                .dash-donut-letter,
                .dash-donut-number {
                  font-size: 12px;
                  white-space: nowrap;
                }
                .dash-donut-number {
                  margin: 0 0 0 auto;
                }
              }
            }
          }
        }

        .dash-stats-container {
          max-width: 600px;

          .dash-stats-list {
            gap: 20px;
            margin-top: 4px;

            .stat-list-item {

              .stat-date {
                @include fluid-type(font-size, 320px, 599px, 14px, 16px);
                color: #1c1c1c;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.02em;
              }

              .dash-stat-right {
                .stat-description,
                .stat-number {
                  @include fluid-type(font-size, 320px, 599px, 14px, 16px);
                }
                .stat-description {
                  font-weight: 400;
                  line-height: 24px;
                  letter-spacing: -0.02em;
                }
                .stat-number {
                  font-weight: 500;
                  line-height: 16px;
                  letter-spacing: 0.04em;
                }
              }
            }
          }
          .dash-mod-subtitle {
            font-size: 10px;
            text-align: center;
            @include fluid-type(margin-bottom, 320px, 599px, 2px, 12px);
          }
          .goto-listing {
            padding-top: 8px;
            padding-bottom: 8px;
            @include fluid-type(font-size, 320px, 599px, 14px, 16px);
            height: fit-content;
          }
        }
        .dash-book-req-holder {
          .dash-reqs-container{
            .dash-req-title-holder{
              @include fluid-type(margin-bottom, 320px, 599px, 12px, 16px);
            }
            .dash-request-container {

            }
          }
        }
      }
    }
  }
}

// .dash-container {
//   .dash-comp-container {
//     .dash-section-box {
//       .dash-book-req-holder {
//         .dash-reqs-container {
//           .dash-request-container {
//             .dash-req-module {
//               padding: 12px;
//               min-height: 126px;
//               height: auto;
//               .dash-req-mod-header {
//                 height: 44px;
//                 .dash-req-uuid,
//                 .dash-req-date {
//                   font-weight: 400;
//                   font-size: 12px;
//                   line-height: 16px;
//                 }
//                 .dash-req-date {
//                   margin: 12px 0 2px 0;
//                 }
//               }
//               .modal-element-click {
//                 .dash-req-title {
//                   font-size: 14px;
//                   line-height: 18px;
//                   letter-spacing: 0.04em;
//                   margin-bottom: 8px;
//                 }
//               }
//               .dash-req-body {
//                 font-weight: 400;
//                 font-size: 12px;
//                 line-height: 16px;
//                 height: fit-content;
//                 align-self: flex-end;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media (min-width: 600px) and (max-width: 904px) {
  .my-brk-donut {
    @include fluid-type(height, 600px, 904px, 200px, 250px);
    // height: 200px;
    margin-right: 0;
    .donut-box {
    }
    .donut-paths {
    }
  }

  .dash-container {
    .dash-comp-container {
      @include fluid-type(margin-top, 600px, 904px, 12px, 24px);

      .my-dash-title {
        font-weight: 700;
        @include fluid-type(font-size, 600px, 904px, 16px, 24px);
        line-height: 28px;
        height: 28px;
      }

      .dash-title-divider {
        margin: 4px 0 16px 0;
      }

      .dash-section-box {
        .dash-section-holder {
          gap: 16px;

          .dash-stats-container,
          .dash-breakdown-container {
            @include fluid-type(padding, 600px, 904px, 12px, 20px);
          }
          
          .dash-mod-title {
            line-height: 24px;
            font-weight: 700;
          }

          .dash-stats-container {
            min-width: 32.95%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            justify-content: space-between;

            .dash-mod-title {
              @include fluid-type(font-size, 600px, 904px, 16px, 20px);
            }

            .goto-listing {
              @include fluid-type(font-size, 600px, 904px, 14px, 16px);
              @include fluid-type(height, 600px, 904px, 36px, 45px);
            }

            .dash-stats-list {
              margin-top: 3px;
              gap: 20px;

              .stat-list-item {
                .dash-stats-icons {
                }
                .dash-stat-right {
                  .stat-description {
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: -0.02em;
                  }
                  .stat-number {
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.04em;
                  }
                }
                .stat-date {
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: -0.02em;
                }
              }
            }
          }
          .dash-breakdown-container {
            min-width: 67.05%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;

            .donut-select-drop {
              width: 70px;
              height: 28px;
              background: #ffffff;
              border: 1px solid #4a4a4a;
              border-radius: 8px;
              padding: 4px 8px;
              .dropdown-menu {
                margin-top: 5px;
              }
              #breakdown-link-container {
                height: 100%;
                font-size: 14px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;
                color: #4a4a4a;
                line-height: 12px;
                padding: 0;
              }
            }

            .dash-mod-title,
            .case-donut-title-holder {
              @include fluid-type(font-size, 600px, 904px, 16px, 20px);
              justify-content: space-between;
              align-items: flex-end;

              .case-donut-title {
                height: 28px;
                font-weight: 700;
                font-size: 16px;
                line-height: 28px;
              }
            }  
          }
          .dash-donut-holder {
            margin: 8px 0 0 0;
            flex-direction: column; 
          
            .donut-stats-container {
              padding: 0;
              // background-color: red;
              max-width: none;
              flex-direction: row;
              flex-wrap: wrap;
              // flex: 100%;
              width: auto;
              column-gap: 9px;
              row-gap: 4px;
              justify-content: space-evenly;
              margin: 0;

              .dash-donut-rows {
                min-width: 90px;
                height: 18px;
                .dash-donut-circle {
                  min-width: 16px;
                  max-width: 16px;
                  // max-width: 75px;
                  width: 16px;
                  height: 16px;
                  margin-right: 4px;
                }
                .dash-donut-letter {
                  font-weight: 400;
                  font-size: 14px;
                  height: 100%;
                  margin-right: 8px;
                  display: flex;
                  flex-wrap: nowrap;
                  width: 100%;
                  align-items: center;
                }
                .dash-donut-number {
                  font-weight: 700;
                  font-size: 14px;
                  position: relative;
                  line-height: 18px;
                }
              }
            }

            .dsc_tab1 {
              margin: auto 0;
              .dash-donut-rows {
                min-width: 64px;
              }
            }
            .dsc_tab2 {
              .dash-donut-rows {
                .dash-donut-letter {
                  font-size: 10px;
                }
                .dash-donut-number {
                  font-size: 10px;
                }
              }
              @media (min-width: 735px) and (max-width: 904px) {
                margin: 0 auto;
                .dash-donut-rows {
                  .dash-donut-letter {
                    @include fluid-type(font-size, 735px, 904px, 10px, 12px);
                  }
                  .dash-donut-number {
                    @include fluid-type(font-size, 735px, 904px, 10px, 12px);
                  }
                }
                // justify-content: flex-start;
              }
            }

            .dsc_tab3 {
              margin: auto 0;
              .dash-donut-rows {
                .dash-donut-letter {
                  font-size: 12px;
                }
                .dash-donut-number {
                  font-size: 12px;
                }
              }
            }
          }
          
          .dash-reqs-container {
            @include fluid-type(padding, 600px, 904px, 14px, 24px);
            .dash-req-title-holder {
              @include fluid-type(font-size, 600px, 904px, 20px, 24px);
              @include fluid-type(margin-bottom, 600px, 904px, 8px, 16px);
            }
            .dash-request-container {
              .dash-req-module {
                .dash-req-mod-header {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 4px;

                  .dash-req-uuid {
                    margin-bottom: 0;
                  }
                  .dash-req-date {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
        .stat-breakdown-holder {
          flex-direction: column;
          gap: 0;

          .stat-breakdown-collapse {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include fluid-type(font-size, 600px, 904px, 16px, 20px);
            font-weight: 700;
            cursor: pointer;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
              0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.04);
            // height: 48px;
            border-radius: 8px 8px 0px 0px;
            @include fluid-type(padding, 600px, 904px, 14px, 24px);
            background-color: $WHITE;
            transition: background-color 0.3s ease-in-out;

            &:hover {
              background-color: rgba(194, 194, 194, 0.089);
            }

            .dashboard-dropdown-icon {
              background-image: url('../../assets/dashboard-dropdown-arrow.png');
              background-repeat: no-repeat;
              background-size: contain;
              width: 30px;
              height: 30px;
              transform: rotate(180deg);
              transition: transform .3s ease-in-out;
            }
            .dashboard-dropdown-icon.collapsed {
              transform: rotate(0deg);
            }
          }

          .stat-breakdown-collapse.collapsed {
            border-radius: 8px;
            transition: border-radius .3s ease-in-out;
          }

          .dash-top-stat-breakdown {
            display: flex;
            overflow: hidden;
            max-height: 427px;
            transition: all 0.3s ease-in-out;
            border-top: 1px solid #e8e8e8;
          }
          .dash-top-stat-breakdown.collapsed {
            max-height: 0;
          }
        }
      }
    }
  }

  // .dash-container {
  //   .dash-comp-container {
  //     .dash-section-box {
  //       .dash-section-holder {
  //         .dash-reqs-container {
  //           padding: 12px;
  //           .dash-request-container {
  //             .dash-req-module {
  //               padding: 12px;
  //               height: fit-content;
  //               .dash-req-mod-header {
  //                 height: fit-content;
  //                 display: flex;
  //                 justify-content: space-between;
  //                 margin-bottom: 4px;

  //                 .dash-req-uuid,
  //                 .dash-req-date {
  //                   font-size: 12px;
  //                   line-height: 16px;
  //                   margin-bottom: 0;
  //                 }
  //               }
  //               .modal-element-click {
  //                 .dash-req-title {
  //                   font-size: 14px;
  //                   line-height: 24px;
  //                   margin-bottom: 8px;
  //                 }
  //               }
  //               .dash-req-body {
  //                 font-size: 12px;
  //                 line-height: 16px;
  //                 height: fit-content;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

@media (min-width: 905px) {
  .dash-container {
    .dash-comp-container {
      .dash-section-box {
        .dash-section-holder {
          .dash-stats-container {
            min-width: 32.95%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .dash-mod-title {
              @include fluid-type(font-size, 905px, 1239px, 20px, 24px);
            }
          }
          .dash-breakdown-container {
            min-width: 67.05%;
            // width: 65.98%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            .dash-mod-title,
            .case-donut-title-holder {
              @include fluid-type(font-size, 905px, 1239px, 20px, 24px);
              

              .case-donut-title {
                height: 28px;
                
              }
              .donut-tab-container {
                column-gap: 10px;
                font-size: 16px;
                .donut-tab-holder {
                  .donut-tab-title {
                  }
                }
              }
            }
            @include fluid-type(font-size, 905px, 1239px, 20px, 24px);
          }
          .dash-donut-holder {
            margin-left: 0;
          }
          .dash-reqs-container {
            .dash-req-title-holder {
            }
            .dash-request-container {
              .dash-req-module {
                .dash-req-mod-header {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 4px;

                  .dash-req-uuid {
                    margin-bottom: 0;
                  }
                  .dash-req-date {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
        .stat-breakdown-holder {
          flex-direction: column;
          gap: 0;

          .stat-breakdown-collapse {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 24px;
            font-weight: 700;
            cursor: pointer;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
              0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.04);
            // height: 48px;
            border-radius: 8px 8px 0px 0px;

            padding: 24px;
            background-color: $WHITE;
            transition: background-color 0.3s ease-in-out;

            &:hover {
              background-color: rgba(194, 194, 194, 0.089);
            }

            .dashboard-dropdown-icon {
              background-image: url('../../assets/dashboard-dropdown-arrow.png');
              background-repeat: no-repeat;
              background-size: contain;
              width: 30px;
              height: 30px;
              transform: rotate(180deg);
              transition: transform .3s ease-in-out;
            }
            .dashboard-dropdown-icon.collapsed {
              transform: rotate(0deg);
            }
          }

          .stat-breakdown-collapse.collapsed {
            border-radius: 8px;
            transition: border-radius .3s ease-in-out;
          }

          .dash-top-stat-breakdown {
            display: flex;
            overflow: hidden;
            max-height: 427px;
            transition: all 0.3s ease-in-out;
            border-top: 1px solid #e8e8e8;
          }
          .dash-top-stat-breakdown.collapsed {
            max-height: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1240px) {
  .dash-container {
    .dash-comp-container {
      .dash-section-box {
        .dash-section-holder {
          .dash-stats-container {
            .dash-mod-title {
            }
          }
          .dash-breakdown-container {
            .dash-mod-title {
            }
          }
        }
      }
    }
  }
}