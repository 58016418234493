.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .nf-title {
    font-size: 240px;
    font-weight: 700;
    line-height: 312.48px;
    color: transparent;
    margin: 120px 0 12px 0;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: $BLUE;
  }

  .nf-subtitle {
    margin-bottom: 48px;
  }

  .nf-link {
    text-decoration: none;
    color: $WHITE;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 36px;

    width: 221px;
    height: 62px;

    background: #2f5bea;
    border: 1px solid #46494d;
    box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.15);
    border-radius: 42px;
  }
}
